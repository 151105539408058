<template>
    <div class="resources" v-if="this.data" data-aos="fade">
        <control-panel 
            :data="this.data" 
            :canDelete="true"
            @onDelete="deleteItem($event)"
            />
        <div class="resources__info">
            <div>
                <v-rating
                    background-color="#b4b7b9"
                    color="#ffbc6e"
                    empty-icon="mdi-star"
                    full-icon="mdi-star"
                    readonly
                    length="5"
                    size="17"
                    :value="this.data.rating"
                ></v-rating>
                <div>
                    <span>{{this.data.rating?this.data.rating:0}}/5, {{this.data.rating_count}} Evaluari</span>
                </div>
            </div>
            <!-- <div>
                <img/>
                <span>Distribuiri</span>
            </div> -->
            <div v-if="this.data.level">
                <span>
                   Pas: {{this.data.level.name}}
                </span>
            </div>
        </div>
        
        <div class="resources__controls">
            <router-link :to="'/main/editor/' + this.data.id">
            <v-btn class="chartarium-button-hollow" rounded elevation="0" color="#fff">
                <span>edit</span>
                <inline-svg src='/img/pencil.svg' width="18" height="18"/>
            </v-btn>
            </router-link>
            <v-btn @click="saveIn(data)" v-if="this.data.system" class="chartarium-button-hollow" rounded elevation="0" color="#fff">
                <span>ADAUGĂ</span>
                <v-icon width="18" height="18">
                    mdi-plus
                </v-icon>
            </v-btn>
            <v-btn @click="moveTo(data)" v-if="!this.data.system" class="chartarium-button-hollow" rounded elevation="0" color="#fff">
                <span>mutare</span>
                <v-icon width="18" height="18">
                    mdi-swap-horizontal
                </v-icon>
            </v-btn>
            <v-btn @click="saveIn(data)" v-if="!this.data.system" class="chartarium-button-hollow" rounded elevation="0" color="#fff">
                <span>copiere</span>
                <inline-svg src='/img/copy.svg' width="18" height="18" fill="#2e4765"/>
            </v-btn>
            <v-btn @click="printResource" class="chartarium-button-hollow" rounded elevation="0" color="#fff">
                <span>print</span>
                <inline-svg src='/img/print.svg' width="18" height="18"/>
            </v-btn>
            <!-- <v-btn class="chartarium-button-hollow" rounded elevation="0" color="#fff">
                <span>distribuire</span>
                <inline-svg src='/img/share.svg' width="18" height="18"/>
            </v-btn> -->
        </div>
        <div class="resources__divider"/>  
        <div class="resources__resource">
            <v-col class="resources__resource--preview">
                <div class="resources__resource--preview-wrapper">
                    <img id="resource_image" v-if="this.data.previews.length !== 0" v-auth-image="$axios.defaults.baseURL+this.data.previews[0].file"/>
                </div>
            </v-col>
            <v-col class="ressources__resource--info">
                <div v-if="this.data.description" class="resources__resource--description">
                    <h1>Descriere</h1>
                    <p>{{this.data.description}}</p>
                </div>
                <div v-if="this.data.description" class="resources__divider"/>  
                <div class="resources__resource--details">
                    <p v-if="this.data.age_group">Categorie varsta: <span>{{this.data.age_group.name}}</span></p>
                    <p v-if="this.data.collection">Colectie: <span>{{this.data.collection.name}}</span></p>
                    <p v-if="this.data.type">Tip document: <span>{{this.data.type.name}}</span></p>
                </div>
                 <!-- <div class="resources__divider"/>  
                <div class="resources__resource--folders">
                    <p>Salvat in:</p>
                </div> -->
            </v-col>
        </div>
         <div class="resources__divider" v-if="this.data.system"/>  
        <div class="resourcse__steps" v-if="this.related && this.data.system">
            <step :data="this.related" :carouselItems="5" :hideDots="true"/>
        </div>    
        <rating-modal 
            v-model="toggleRating" 
            :btnIcon="'check'" 
            :btnIconSize="24" 
            :title="'Evaluare'" 
            :btnTitle="'trimite'" 
            @refetch="getResource()"
        />
        <move-resource-modal
            v-model="toggleMoveModal"
            :btnIcon="'save'" 
            :btnIconSize="24" 
            :title="'Muta In'" 
            :btnTitle="'Salveaza'"
            :selectedItem="selectedItem"
            :moveResource="true" 
            :isActualMove="true"
        />
         <copy-resource-modal
            v-model="toggleCopyModal"
            :btnIcon="'save'" 
            :btnIconSize="24" 
            :title="'Copiaza In'" 
            :btnTitle="'Salveaza'"
            :selectedItem="selectedItem"
            :moveResource="true" 
            :isActualMove="false"
        />
        <delete-modal
            v-model="toggleDeleteModal" 
            :mdi="true"
            :btnIcon="'mdi-delete'" 
            :btnIconSize="24" 
            :title="'Sterge Resursa'" 
            :btnTitle="'Sterge'" 
            :selectedItem="selectedItem"
            :isResource="true"
        />
    </div>
</template>
<script>
import ControlPanel from '../../components/organisms/ControlPanel.vue'
import Step from '../../components/organisms/Step.vue';
import Modal from '../../components/molecules/Modal.vue';
import DeleteModal from '../../components/molecules/DeleteModal.vue';
import MoveResource from '../../components/molecules/ResourceMoveToModal.vue';
import CopyResource from '../../components/molecules/ResourceMoveToModal.vue';
import InlineSvg from 'vue-inline-svg'; 
export default {
    components:{
        'control-panel':ControlPanel,
        'step': Step,
        'inline-svg': InlineSvg,
        'rating-modal':Modal,
        'delete-modal':DeleteModal,
        'move-resource-modal':MoveResource,
        'copy-resource-modal':CopyResource
    },
    data(){
        return {
            data:null,
            related:null,
            selectedItem:null,
            toggleRating:false,
            toggleMoveModal:false,
            toggleCopyModal:false,
            toggleDeleteModal:false,
        }
    },
    mounted(){
        this.getResource();
        this.getRelated();
    },
    methods: {
        saveIn(item){
            this.selectedItem = item;
            this.toggleCopyModal=true;
        },
        moveTo(item){
            this.selectedItem = item;
            this.toggleMoveModal=true;
        },
        deleteItem(item){
            this.selectedItem = item;
            this.toggleDeleteModal=true;
        },
        printResource(){

            // Create a fake iframe
            const iframe = document.createElement('iframe');

            // Make it hidden
            iframe.style.height = 0;
            iframe.style.visibility = 'hidden';
            iframe.style.width = 0;

            // Set the iframe's source
            iframe.setAttribute('srcdoc', '<html><body></body></html>');

            document.body.appendChild(iframe);

            iframe.contentWindow.addEventListener('afterprint', function () {
                iframe.parentNode.removeChild(iframe);
            });

            iframe.addEventListener('load', function () {
                // Clone the image
                const image = document.getElementById('resource_image').cloneNode();
                image.style.width = '94%';

                // Append the image to the iframe's body
                const body = iframe.contentDocument.body;
                body.style.textAlign = 'center';
                body.appendChild(image);

                image.addEventListener('load', function () {
                    // Invoke the print when the image is ready
                    iframe.contentWindow.print();
                });
            });
                
        },
        getResource(){
            this.$axios.get('/resources/'+this.$route.params.resource_id)
                .then(res=>{
                    this.data = res.data;
                })
                .catch(err=>{
                    this.$toasted.error(err)
                })
        },
        getRelated(){
            this.$axios.get('/resources/'+this.$route.params.resource_id+'/related')
                .then(res=>{
                    this.related = res.data;
                })
                // .catch(err=>{
                //     this.$toasted.error(err)
                // })
        }
    }
}
</script>