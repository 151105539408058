<template>
    <div class="template-category" style="margin-top: -15px;">
        <div class="category" v-for="category in filteredData">
            <div class="title" style="margin-bottom: 10px; font-size: 14px !important; cursor: pointer;" @click="category.open = !category.open; $forceUpdate();">
                <div class="pull-right">
                    <v-icon>{{ (category.open) ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                </div>
                {{ category.name }}
            </div>
            <div class="children">
                <template-category @select="$emit('select', $event)" :data="category" :key="category.id" v-if="category.children.length > 0 && category.open" />
                <div class="templates" v-if="category.open">
                    <v-row>
                        <v-col cols="6" v-for="template in category.templates" style="padding: none;">
                            <div @click="$emit('select', template)" class="template-holder" v-ripple>
                                <img :src="'https://api.chartarium.ro/storage/templates' + template.file" alt="" width="100%">
                                <div class="name">
                                    {{ template.name }}
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
        <!-- <div class="category general" v-if="data.templates.length > 0">
            <div class="templates">
                <v-row>
                    <v-col cols="6" v-for="template in data.templates" style="padding: none;">
                        1
                        <div @click="$emit('select', template)" class="template-holder" v-ripple>
                            <img :src="'https://api.chartarium.ro/storage/templates' + template.file"  alt="" width="100%">
                            <div class="name">
                                {{ template.name }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div> -->
    </div>
    </div>
</template>
<style lang="scss">
.templates {
    margin-bottom: 15px;
}

.main-navigation__level-2-editor {
    height: calc(100vh - 95px - 50px);
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    top: 50px;
    
    .search {
        padding-top: 7px;
        display: inline-block;
        width: 290px;
        background-color: #f5f6fa;
        border: 1px solid #ececec;
        position: fixed;
        z-index: 99;
        top: 80px;
        height: 50px;

        .v-input {
            .v-input__control {
                .v-input__slot {
                    padding-left: 10px !important;   
                    padding-right: 30px !important; 
                }
            }
        }
    }
}

.title {
    position: relative;
    width: 100%;
    font-size: 14px !important;

    .pull-right {
        position: absolute;
        right: 0;
    }

}

.template-holder {
    padding: 25px 25px 25px 25px;
    background-color: #DBDCE0;
    border-radius: 6px;
    position: relative;
    .name {
        display: none;
        opacity: 0;
        font-size: 10px;
        padding: 3px 0px 0px 0px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        ;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        border-radius: 0px 0px 6px 6px;
    }
}
.template-category {
    .children {
        margin-top: 20px;
        padding-left: 15px;
        border-left: 2px dashed #cfcfcf;
        margin-left: 5px;
    }
}
</style>
<script>
import TemplateCategory from './TemplateCategory.vue';
export default {
    name: 'TemplateCategory',
    props: ['data', 'filter'],
    components: {
        TemplateCategory,
    },
    computed: {
        filteredData() {
            if(!this.filter) {
                return this.data.children;
            }
            let templates = this.extractTemplates([], this.data, this.filter);
            return [{
                name: 'Rezultat cautare',
                children: [],
                templates: templates,
                open: true,
            }];
        }
    },
    methods: {
        extractTemplates(result, category, filter) {
            // Parse all of the templates in this category
            for (var i = 0; i < category.templates.length; i++) {
                let template = category.templates[i];
                if(template.name.includes(filter)) {
                    result.push(template);
                }
            }
            // Run the same function on every child
            for (var i = 0; i < category.children.length; i++) {
                let child = category.children[i];
                console.log('>> child', child.name);
                result = this.extractTemplates(result, child, filter);
            }
            return result;
        }
    }

}
</script>