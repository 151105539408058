<template>
    <div class="resources" v-if="this.data && this.filters" data-aos="fade">
        <control-panel 
            :data="this.data" 
            :canEdit="true" 
            :canDelete="true"
            @onEdit="editItem($event)"
            @onDelete="deleteItem($event)"
        />
        <div class="resources__filters">
            <filter-toggle :filterName="'Categorie varsta'" :filter="this.filters.ages" @addFilter="filterBy($event, 'age_group_id')"/>
            <filter-toggle :filterName="'Colectie'" :filter="this.filters.collections" @addFilter="filterBy($event, 'collection_id')"/>
            <filter-toggle :filterName="'Tip document'" :filter="this.filters.types" @addFilter="filterBy($event, 'type_id')"/>
        </div>
        <div class="resources__divider"/>     
        <empty-folder v-if="this.data.children.length === 0 && this.data.resources.length === 0" class="resources__folders--empty"/>
        <div class="resources__folders" v-if="this.data.children.length !== 0">
            <div  class="resources__folders-item" v-for="folder in this.data.children" :key="folder.id">
                <p class="resources__folders-item--title">{{folder.name}}</p>
                <!-- ar trebui sa fie resources in loc de children -->
                <p class="resources__folders-item--count">{{folder.resource_count}} Resurse</p>
                <img src="/img/folder-preview.png"/>
                <div class="resources__folders-item--hover">
                    <v-btn @click="goToFolder(folder)" rounded elevation="0" color="#2E4DD457">
                        <img src="/img/eye.png"/>
                        <span>vizualizare</span>
                    </v-btn>
                    <v-btn @click="editItem(folder)" rounded elevation="0" color="#2E4DD457">
                        <img src="/img/pencil.png"/>
                        <span>editare</span>
                    </v-btn>
                </div>
            </div>
        </div>
        <div class="resources__folders resources__folders--resource" v-if="this.data.resources.length !== 0">
            <div class="step__item" :class="{'step__item--placeholder':resource.previews.length === 0}" v-for="resource in this.data.resources" :key="resource.id">
                <img v-if="resource.previews.length !== 0" v-auth-image="$axios.defaults.baseURL+resource.previews[0].file" height="100%" width="100%"/>

                <div class="demofeat" style="position: absolute; top: 10px; left: 10px;">
                    <div style="background-color: #ef3f2b; color: #fff; display: inline-block; margin-right: 10px; border-radius: 6px; padding: 5px 10px; font-weight: bold; font-size: 14px;" v-if="resource.demo">DEMO</div>
                    <div style="background-color: #ef3f2b; color: #fff; display: inline-block; margin-right: 10px; border-radius: 6px; padding: 5px 10px; font-weight: bold; font-size: 14px;"  v-if="resource.featured">FEATURED</div>
                </div>

                <div class="step__item--hover">
                    <v-btn @click="goToResource(resource)" rounded elevation="0" color="#2E4DD457">
                        <img src="/img/eye.png"/>
                        <span>vizualizare</span>
                    </v-btn>
                    <router-link :to="'/main/editor/' + resource.id">
                        <v-btn rounded elevation="0" color="#2E4DD457">
                            <img src="/img/pencil.png"/>
                            <span>editare</span>
                        </v-btn>
                    </router-link>
                    <v-btn @click="saveIn(resource)" rounded elevation="0" color="#2E4DD457">
                        <img src="/img/save.png"/>
                        <span>salveaza in</span>
                    </v-btn>  
                </div>      
            </div>
        </div>     
        <folder-modal 
            v-model="toggleEditModal" 
            :btnIcon="'save'" 
            :btnIconSize="24" 
            :title="'Modifica Dosar'" 
            :btnTitle="'Salveaza'" 
            :moveResource="false" 
            :editFolder="true"
            :selectedItem="selectedItem"
            @refetchFolder="getFolder"
        />  
        <move-resource-modal
            v-model="toggleMoveModal"
            :btnIcon="'save'" 
            :btnIconSize="24" 
            :title="'Salveaza In'" 
            :btnTitle="'Salveaza'"
            :selectedItem="selectedItem"
            :moveResource="true" 
            :isActualMove="false"
            @refetchFolder="getFolder"
        />
        <delete-modal
            v-model="toggleDeleteModal" 
            :mdi="true"
            :btnIcon="'mdi-delete'" 
            :btnIconSize="24" 
            :title="'Sterge Dosar'" 
            :btnTitle="'Sterge'" 
            :selectedItem="selectedItem"
            :isResource="false"
        />  
    </div>
</template>
<script>
import ControlPanel from '../../components/organisms/ControlPanel.vue'
import FilterToggle from '../../components/molecules/FilterToggle.vue';
import EmptyFolder from '../../components/atoms/EmptyFolder.vue';
import Modal from '../../components/molecules/CrudFolderModal.vue';
import DeleteModal from '../../components/molecules/DeleteModal.vue';
import MoveResource from '../../components/molecules/ResourceMoveToModal.vue';
export default {
    components:{
        'control-panel':ControlPanel,
        'filter-toggle':FilterToggle,
        'empty-folder':EmptyFolder,
        'folder-modal':Modal,
        'delete-modal':DeleteModal,
        'move-resource-modal':MoveResource
    },
    data(){
        return {
            data:null,
            toggleEditModal:false,
            toggleMoveModal:false,
            toggleDeleteModal:false,
            selectedItem:null,
            filters:null,
            params:{
                age_group_id:null,
                collection_id:null,
                type_id:null,
            }
        }
    },
    mounted(){
        this.getFolder();
        this.getFilters();
        this.$root.$on('refetchFolders', ()=> {
            this.getFolder();
        });
    },
    methods: {
        editItem(item){
            this.selectedItem = item;
            this.toggleEditModal=true;
        },
        saveIn(item){
            this.selectedItem = item;
            this.toggleMoveModal=true;
        },
        deleteItem(item){
            this.selectedItem = item;
            this.toggleDeleteModal=true;
        },
        getFolder(params){
            this.$axios.get('/folders/'+this.$route.params.folders_id,{ params:params })
                .then(res=>{
                    this.data = res.data;
                })
                .catch(err=>{
                    this.$toasted.error(err)
                })
        },
        getFilters(){
            var self = this;
            function types(){
                return self.$axios.get('/resource-types');
            }

            function collections(){
                return self.$axios.get('/resource-collections');
            }

            function ageGroups(){
                 return self.$axios.get('/resource-age-groups');
            }

            Promise.all([types(), collections(), ageGroups()])
                    .then(res=>{
                        this.filters = {};
                        this.filters.types = res[0].data;
                        this.filters.collections = res[1].data;
                        this.filters.ages = res[2].data;
                    });

        },
        filterBy(id,property){
            this.params[property] = id;
            for(const key in this.params){
                if (this.params[key] === null){
                    delete this.params[key];
                }
            } 
            this.getFolder(this.params) 
        },
        goToResource(item){
            this.$router.push('/main/folders/'+this.$route.params.folders_id+'/resource/'+item.id);
        },
        goToFolder(item){
            this.$router.push('/main/folders/'+item.id)
        }
    }
}
</script>