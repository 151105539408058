<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="820"
        style="z-index: 999999;"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form">
            <div class="app-modal__card--content">
                <h1 class="app-modal__title">Updateaza Resursa</h1>
                <div class="app-modal__card--folder-title">
                    <v-text-field 
                        v-model="temp.name" 
                        outlined 
                        label="Titlu Resursa"
                        :rules="[() => !!temp.name || 'Campul este obligatoriu']"
                        ref="title"
                        dense
                        hide-details
                    ></v-text-field>
                    <br>
                    <treeselect valueFormat="object" placeholder="Varsta" v-model="temp.age_groups" :multiple="true" :options="ageGroups" />

                    <!-- <v-select
                        v-model="temp.age_group_id" 
                        outlined 
                        label="Varsta"
                        ref="level"
                        :items="ageGroups"
                        item-text="name"
                        item-value="id"
                        dense
                        hide-details
                    ></v-select> -->
                    <br>
                    <v-select
                        v-model="temp.level_id" 
                        outlined 
                        label="Pas"
                        ref="level"
                        :items="levels"
                        item-text="name"
                        item-value="id"
                        dense
                        hide-details
                    ></v-select>
                    <br>
                    <v-select
                        v-model="temp.type_id" 
                        outlined 
                        label="Tip"
                        ref="type"
                        :items="types"
                        item-text="name"
                        item-value="id"
                        dense
                        hide-details
                    ></v-select>
                    <br>
                    <v-select
                        v-model="temp.collection_id" 
                        outlined 
                        label="Colectie"
                        ref="collection"
                        :items="collections"
                        item-text="name"
                        item-value="id"
                        dense
                        hide-details
                    ></v-select>
                    <br>
                    <v-select
                        v-model="temp.demo" 
                        outlined 
                        label="Demo"
                        ref="demo"
                        :items="[{name: 'Nu', id: 0 },{name: 'Da', id: 1}]"
                        item-text="name"
                        item-value="id"
                        dense
                        hide-details
                    ></v-select>
                    <br>
                    <v-select
                        v-model="temp.featured" 
                        outlined 
                        label="Featured pe site"
                        ref="featured"
                        :items="[{name: 'Nu', id: 0 },{name: 'Da', id: 1}]"
                        item-text="name"
                        item-value="id"
                        dense
                        hide-details
                    ></v-select>
                    <br>
                </div>
            </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="postFolder">
                    <inline-svg :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#2e4dd4"/>
                    <span>{{btnTitle}}</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
    props:['title','btnIcon', 'btnIconSize','btnTitle','value','moveResource', 'selectedItem', 'postData', 'postData', 'orientation', 'resource'],
    components:{
        'inline-svg':InlineSvg,
        Treeselect,
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            temp:{
                name:null,
                parent_id:"",
                level_id: null,
                type_id: null,
                collection_id: null,
                age_group_id: null,
            },
            selected:null,
            folders:null,
            rootFolderOpened:false,
            lvlOneFolderOpened:null,
            lvlTwoFolderOpened:null,
            lvlOneSelectedFolder:null,
            lvlTwoSelectedFolder:null,
            loading: false,
            breadcrumb:[],
            levels: null,
            types: null,
            collections: null,
            ageGroups: null,
        }
    },
    mounted(){

        //console.log('res in modal', this.resource);
        this.temp = JSON.parse(JSON.stringify(this.resource));

        this.getLevels();
        this.getCollections();
        this.getAgeGroups();
        this.getTypes();
        this.getFolders();
    },
    watch: {
        selectedItem(newItem){
            if(this.editFolder){
                //console.log(newItem)
                this.recursiveSearch(this.folders, newItem.id)
            }
        },
        'temp.parent_id'(newId,oldId){
            this.breadcrumb = [];
            this.createBreadcrumbs(this.folders, newId);
            this.breadcrumb.reverse();
        }
    },
    methods: {
        async getAgeGroups() {
            try {
                let response = await this.$axios.get('resource-age-groups');
                this.ageGroups = response.data.map(e => {
                    e.label = e.name;
                    return e;
                });
                //this.temp.age_group_id = this.ageGroups[0].id;
            } catch (error) {
                console.log(error);
            }
        },
        async getCollections() {
            try {
                let response = await this.$axios.get('resource-collections');
                this.collections = response.data;
                //this.temp.collection_id = this.collections[0].id;
            } catch (error) {
                console.log(error);
            }
        },
        async getTypes() {
            try {
                let response = await this.$axios.get('resource-types');
                this.types = response.data;
                //this.temp.type_id = this.types[0].id;
            } catch (error) {
                console.log(error);
            }
        },
        async getLevels() {
            try {
                let response = await this.$axios.get('resource-levels');
                this.levels = response.data;
                //this.temp.level_id = this.levels[0].id;
            } catch (error) {
                console.log(error);
            }
        },
        close(){
            this.show = false;
            this.rootFolderOpened = false;
            this.lvlOneFolderOpened = null;
            this.lvlTwoFolderOpened = null;
            this.lvlOneSelectedFolder = null;
            this.temp = JSON.parse(JSON.stringify(this.resource));
            this.$refs.form.resetValidation();
        },
        openRoot(){
            if(this.rootFolderOpened) {
                this.lvlOneFolderOpened = null;
                this.lvlTwoFolderOpened = null;
                this.lvlOneSelectedFolder = null;
                this.lvlTwoSelectedFolder = null;

                this.checkSelectedFolder(this.$refs.lvl_one_folder_id);
                this.checkSelectedFolder(this.$refs.lvl_two_folder_id);
                this.checkSelectedFolder(this.$refs.lvl_three_folder_id);
            }
            this.rootFolderOpened = !this.rootFolderOpened;
        },
        openFolderLvlOne(folder){
            if(this.lvlOneFolderOpened === null){
                this.lvlOneFolderOpened = folder.id;
                this.lvlOneSelectedFolder = folder;
            } else if(this.lvlOneSelectedFolder.id === folder.id){
               this.lvlOneFolderOpened = null;
                this.lvlTwoFolderOpened = null;
                this.lvlOneSelectedFolder = null;
                this.lvlTwoSelectedFolder = null;

                this.checkSelectedFolder(this.$refs.lvl_two_folder_id);
                this.checkSelectedFolder(this.$refs.lvl_three_folder_id);
            } else {
                this.lvlOneFolderOpened = folder.id;
                this.lvlOneSelectedFolder = folder;
                this.lvlTwoFolderOpened= null;
                this.lvlTwoSelectedFolder = null;

                this.checkSelectedFolder(this.$refs.lvl_two_folder_id);
                this.checkSelectedFolder(this.$refs.lvl_three_folder_id);
            }  
        },
        openFolderLvlTwo(folder){
            if(this.lvlTwoFolderOpened === null){
                this.lvlTwoFolderOpened = folder.id;
                this.lvlTwoSelectedFolder = folder;
            } else if(this.lvlTwoSelectedFolder.id === folder.id){
                this.lvlTwoFolderOpened = null;
                this.lvlTwoSelectedFolder = null;

                this.checkSelectedFolder(this.$refs.lvl_three_folder_id);
            } else {
                this.lvlTwoFolderOpened = folder.id;
                this.lvlTwoSelectedFolder = folder;

                this.checkSelectedFolder(this.$refs.lvl_three_folder_id);
            }  
        },
        validate(){
            let valid = true;
            if(this.temp.name === null || this.temp.parent_id === null || this.temp.name === undefined || this.temp.parent_id === undefined) {
                valid = false;
            }

            return valid;
        },
        getFolders(){
            this.$axios.get('/folders')
                .then(res=>{
                    this.folders = res.data;
                })
                .catch(err=>{
                    this.$toasted.error(err)
                })
        },
        dataURLtoFile(dataurl, filename) { 
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
                
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            
            return new File([u8arr], filename, {type:mime});
        },
        async postFolder(){
        
            this.loading = true;
            /*if(!this.validate()) {
               this.$toasted.error('Toate campurile trebuiesc completate');
               this.loading = false;
               return; 
            }*/

            // POST DATA
            try {

                let postData = new FormData();

                /*let orientation = 'portrait';
                if(this.$route.query.orientation) {
                    orientation = this.$route.query.orientation;
                }*/

                postData.append('collection_id', this.temp.collection_id);
                postData.append('type_id', this.temp.type_id);
                postData.append('level_id', this.temp.level_id);
                //postData.append('age_group_id', this.temp.age_group_id);
                //postData.append('parent_id', this.temp.parent_id);
                postData.append('age_groups', this.temp.age_groups.map(e => { return e.id }));
                postData.append('name', this.temp.name);
                postData.append('scene', this.postData.scene);
                postData.append('orientation', this.orientation);
                postData.append('demo', this.temp.demo);
                postData.append('featured', this.temp.featured);
                let tempFile = this.dataURLtoFile(this.postData.render, 'render.png');
                postData.append('render', tempFile);


                let response = await this.$axios.post('/resources/' + this.resource.id, postData);
                console.log(response);
                this.$toasted.success('Resource saved');        
                //this.$router.push('/main/folders/' + this.temp.parent_id);
                this.show = false;
            } catch (error) {
                console.log(error);
                this.$toasted.error('An error occured');
            }


        },
        recursiveSearch(data, matchingItem){
            for(let t=0;t<data.length;t++){
                if(data[t].id == matchingItem){
                    this.selected = JSON.parse(JSON.stringify(data[t]));
                    this.temp.name = this.selected.name;
                    if(this.selected.parent_id!==null){
                        this.temp.parent_id = this.selected.parent_id;
                    } else {
                        this.temp.parent_id = ""
                    }
                    return data[t];
                } else if (data[t].children.length !==0){
                    let result = null;
                    for(let i=0; result == null && i < data[t].children.length; i++){
                        result = this.recursiveSearch(data[t].children, matchingItem);
                    }                    
                    if(result) return result;
                }
            }
            return null;           
        },

        createBreadcrumbs(data, matchingItem){
            /*for(let t=0;t<data.length;t++){
                if(data[t].id == matchingItem){
                    //console.log(data[t]);
                    this.breadcrumb.push({text:data[t].name});
                    if(data[t].parent_id != null){
                        //console.log(data[t].id, data[t].parent_id)
                        this.createBreadcrumbs(this.folders, data[t].parent_id)
                    }
                    if (data[t].parent_id == null){
                         this.breadcrumb.push({text:"Dosarele mele"});
                    }
                    //return data[t];                   
                }  else if (data[t].children.length !==0){
                    let result = null;
                    for(let i=0; result == null && i < data[t].children.length; i++){
                        result = this.createBreadcrumbs(data[t].children, matchingItem);
                    }                    
                    if(result) return result;
                }
            }*/
            return null;   
        },
        checkSelectedFolder(arr){
            if(arr != undefined || arr != null){
                arr.forEach(element => {
                    if(element.isActive === true){
                        this.temp.parent_id = null;
                    }
                    return; 
                });
            }
        }
    }

}
</script>