<template>
    <div class="view plans">
        <div class="title">
            Planuri
            <div class="actions">
                <v-btn @click="modals.add = true" rounded elevation="0" class="btn-empty">
                    <span>adauga plan</span>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </div>
        </div><br>
        <div class="plans" v-if="plans">
            <v-simple-table class="w-100">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Nume
                            </th>
                            <th class="text-left">
                                Tip
                            </th>
                            <th class="text-left">
                                Max Useri
                            </th>
                            <th class="text-left">
                                Foldere
                            </th>
                            <th class="text-left">
                                Acces Resurse
                            </th>
                            <th class="text-left">
                                Editare Resurse
                            </th>
                            <th class="text-left">
                                Editare Text
                            </th>
                            <th class="text-left">
                                Editare Logo
                            </th>
                            <th class="text-left">
                                Sistem
                            </th>
                            <th class="text-right">
                                Actiuni
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in plans" :key="item.name">
                            <td><b>{{ item.name }}</b></td>
                            <td>{{ item.type.toUpperCase() }}</td>
                            <td>{{ item.max_users }}</td>
                            <td>{{ item.max_folders }}</td>
                            <td>
                                <v-icon color="green" v-if="item.resource_access">mdi-check</v-icon>
                                <v-icon color="red" v-else>mdi-close</v-icon>
                            </td>
                            <td>
                                <v-icon color="green" v-if="item.resource_edit">mdi-check</v-icon>
                                <v-icon color="red" v-else>mdi-close</v-icon>
                            </td>
                            <td>
                                <v-icon color="green" v-if="item.edit_text">mdi-check</v-icon>
                                <v-icon color="red" v-else>mdi-close</v-icon>
                            </td>
                            <td>
                                <v-icon color="green" v-if="item.edit_logo">mdi-check</v-icon>
                                <v-icon color="red" v-else>mdi-close</v-icon>
                            </td>
                            <td>
                                <v-icon color="green" v-if="item.system">mdi-check</v-icon>
                                <v-icon color="red" v-else>mdi-close</v-icon>
                            </td>
                            <td class="text-right">
                            	<v-icon @click="selected = item; modals.delete = true;" class="table-action" v-if="!item.system">mdi-delete</v-icon>
                            	<v-icon @click="selected = item; modals.edit = true;" class="table-action" v-if="!item.system">mdi-pencil</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div class="modals">
        	<add-plan v-model="modals.add" @updated="getPlans()"  />
            <edit-plan v-if="selected" :data="selected" v-model="modals.edit" @updated="getPlans()" />
            <delete-plan v-if="selected" :data="selected" v-model="modals.dek" @updated="getPlans()" />
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "Plans.scss";
</style>
<script>
import AddPlan from './modals/AddPlan.vue';
import EditPlan from './modals/EditPlan.vue';
import DeletePlan from './modals/DeletePlan.vue';
export default {
    name: "PlansView",
    data() {
        return {
            plans: null,
            selected: null,
            modals: {
            	delete: false,
            	add: false,
            	edit: false,
            }
        }
    },
    components: {
    	AddPlan,
        EditPlan,
        DeletePlan,
    },
    async mounted() {
        await this.getPlans();
    },
    methods: {
        async getPlans() {
            let response = await this.$axios.get('plans');
            this.plans = response.data;
        }
    }
}
</script>