<template>
    <div class="main-navigation">
        <!-- Level 1 menu -->
        <div class="main-navigation__level-1" v-if="data.length === 5" data-aos="fade">
            <div v-for="menuItem in data" :key='menuItem.id'>
                <div class="main-navigation__level-1--title" :class="{'main-navigation__level-1--active':expandedSection===menuItem.name}" @click="expandSection(menuItem)">{{menuItem.name}}</div>
                <transition name='accordion' v-on:before-enter="beforeEnterV" v-on:enter="enterV" v-on:before-leave="beforeLeaveV" v-on:leave="leaveV">
                    <div v-if="menuItem.children" class="main-navigation__level-1--section" v-show="expandedSection===menuItem.name">
                        <div class="main-navigation__level-1--subtitle" :class="{'main-navigation__level-1--open-level-2':(selectedSectionItem?selectedSectionItem.id:null)===item.id}" v-for="item in menuItem.children" :key="item.id" @click="selectSectionItem(item)">
                            <div class="upper-cutout"></div>
                            <div class="lower-cutout"></div>
                            {{item.name}}
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <!-- Level 2 menu -->
        <div class="main-navigation__level-2-editor" v-show="showLevelTwo">
            <div class="search">
                <v-text-field v-model="filter" placeholder="Cauta imagine..." clearable rounded dense prepend-inner-icon="mdi-magnify"></v-text-field>

            </div>
            <div v-if="selectedSectionItem" class="main-navigation__level-2-editor--wrapper">
                <template-category :filter="filter" @select="selectEditor($event)" :data="selectedSectionItem" />
            </div>
        </div>
        <div class="close-button--shadow" v-if="selectedSectionItem && selectedSectionItem.name !== 'Page'">
            <div class="close-button--shape" @click="toggleLevelTwo">
                <v-icon color="#D4D9DF">{{showLevelTwo?`mdi-chevron-left`:`mdi-chevron-right`}}</v-icon>
            </div>
        </div>
    </div>
</template>
<script>
import TemplateCategory from './TemplateCategory.vue';
export default {
    data() {
        return {
            filter: '',
            data: [],
            expandedSection: null,
            selectedSectionItem: null,
            showLevelTwo: true,
        }
    },
    components: {
        TemplateCategory,
    },
    async mounted() {
        await this.getData();
        this.$root.$on('refetchMenu', () => {
            this.expandedSection = null,
                this.selectedSectionItem = null,
                this.expandedSubSection = null,
                this.selectedSubSectionItem = null,
                this.getData()
        });
    },
    beforeDestroy() {
        this.$root.$off('openEditorLevelTwo');
        this.$root.$off('selectEditor');
    },
    methods: {
        //Expand Root Section
        expandSection(item) {
            this.selectedSectionItem = null;
            if (this.expandedSection === item.name) {
                this.expandedSection = null;
            } else {
                this.expandedSection = item.name;
                this.showLevelTwo = false;
            }

            if (this.expandedSection === this.data[0].name || this.expandedSection === this.data[2].name) {
                if (this.expandedSection == this.data[0].name) {
                    this.$root.$emit('openMenu', this.expandedSection);
                    this.$router.push(`/main/file-manager/20`);
                } else {
                    this.$root.$emit('openMenu', this.expandedSection);
                    this.$router.push(`/main/folders/1`);
                }
            }

            if (this.expandedSection == 'Planuri') {
                this.expandedSection = null;
                this.$router.push('/main/plans');
            }
            if (this.expandedSection == 'Conturi') {
                this.expandedSection = null;
                this.$router.push('/main/accounts');
            }

        },
        //Select Menu Item in Expanded Root Section
        selectSectionItem(item) {
            this.selectedSectionItem = JSON.parse(JSON.stringify(item));
            this.showLevelTwo = true;
            if (this.selectedSectionItem.name === this.data[1].children[0].name) {
                this.$root.$emit('openEditorLevelTwo', false);
            } else {
                this.$root.$emit('openEditorLevelTwo', true);
            }
            if (this.expandedSection === this.data[2].name) {
                if (item.id !== parseInt(this.$route.params.folders_id)) {
                    this.$router.push('/main/folders/' + item.id);
                }
            }
        },
        selectEditor(item) {
            if (item.type == 'icon') {
                let template = {
                    component: 'v-image',
                    type: 'image',
                    name: 'image1',
                    x: 1000,
                    y: 1200,
                    width: 300,
                    height: 300,
                    stroke: '#000000',
                    opacity: 1,
                    strokeWidth: 0,
                    draggable: true,
                    keepRatio: true,
                    shadowColor: '#000000',
                    shadowBlur: 20,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    shadowOpacity: 0,
                }
                template.url = "https://api.chartarium.ro/storage/templates" + item.file;
                this.$root.$emit('selectEditor', template)
            } else {
                this.$root.$emit('selectEditor', item);
            }
        },
        // Toggle Level 2 Menu
        toggleLevelTwo() {
            this.showLevelTwo = !this.showLevelTwo;
            this.$root.$emit('openEditorLevelTwo', this.showLevelTwo);
        },
        async getData() {
            try {
                this.data = [];
                let resource = { id: 'resurse', name: 'manager fisiere', children: [] }
                let editor = {
                    id: 'editor',
                    name: 'editor resurse',
                    children: [{
                            id: 9999999991,
                            name: 'Sistem',
                            parent_id: null,
                            children: [
                                // Text items
                                {
                                    //id: 9999999992,
                                    name: 'Text',
                                    //parent_id: 9999999991,
                                    children: [],
                                    open: true,
                                    templates: [{
                                            //id: 9999999993,
                                            name: 'Titlu',
                                            type: 'text',
                                            component: 'v-text',
                                            file: "/sistem/titlu.svg",
                                            x: 1200,
                                            y: 200,
                                            text: 'Simple Text',
                                            fontSize: 120,
                                            fontFamily: 'Patrick Hand',
                                            verticalAlign: 'left',
                                            fontStyle: 'normal',
                                            textDecoration: '',
                                            fill: '#797778FF',
                                            stroke: '#000',
                                            strokeWidth: 0,
                                            draggable: true,
                                            keepRatio: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 20,
                                            shadowOffsetX: 0,
                                            shadowOffsetY: 0,
                                            shadowOpacity: 0,
                                        },
                                        {
                                            //id: 9999999994,
                                            name: 'Continut Text',
                                            type: 'text',
                                            component: 'v-text',
                                            fill: 'red',
                                            x: 1200,
                                            y: 600,
                                            file: "/sistem/text.svg",
                                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam congue eros a ornare mollis. Pellentesque hendrerit elit ac laoreet porta. Nam sit amet ligula id magna egestas mattis. Maecenas eget tortor justo. Suspendisse porta lorem id dictum pretium. Sed fringilla et orci fermentum finibus. Cras tincidunt commodo orci vitae ultricies.',
                                            verticalAlign: 'left',
                                            width: 1200,
                                            fontSize: 90,
                                            fontFamily: 'System',
                                            fontStyle: '',
                                            textDecoration: '',
                                            fill: '#000000FF',
                                            stroke: '#000',
                                            strokeWidth: 0,
                                            draggable: true,
                                            keepRatio: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 20,
                                            shadowOffsetX: 0,
                                            shadowOffsetY: 0,
                                            shadowOpacity: 0,
                                        },
                                    ]
                                },
                                {
                                    name: 'Logo',
                                    children: [],
                                    open: true,
                                    templates: [
                                        {
                                            component: 'v-image',
                                            type: 'image',
                                            name: 'Logo Chartarium',
                                            url: "https://api.chartarium.ro/storage/templates/sistem/Chartarium_Logo_G1.svg",
                                            file: "/sistem/Chartarium_Logo_G1.svg",
                                            x: 1000,
                                            y: 1200,
                                            width: 1000,
                                            height: 1000,
                                            stroke: '#000000',
                                            opacity: 1,
                                            strokeWidth: 0,
                                            draggable: true,
                                            keepRatio: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 20,
                                            shadowOffsetX: 0,
                                            shadowOffsetY: 0,
                                            shadowOpacity: 0,
                                        },
                                        {
                                            component: 'v-image',
                                            type: 'image',
                                            name: 'Logo Chartarium',
                                            url: "https://api.chartarium.ro/storage/templates/sistem/Chartarium_Logo_G2.svg",
                                            file: "/sistem/Chartarium_Logo_G2.svg",
                                            x: 1000,
                                            y: 1200,
                                            width: 1000,
                                            height: 1000,
                                            stroke: '#000000',
                                            opacity: 1,
                                            strokeWidth: 0,
                                            draggable: true,
                                            keepRatio: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 20,
                                            shadowOffsetX: 0,
                                            shadowOffsetY: 0,
                                            shadowOpacity: 0,
                                        },
                                        {
                                            component: 'v-image',
                                            type: 'image',
                                            name: 'Logo Chartarium',
                                            url: "https://api.chartarium.ro/storage/templates/sistem/Chartarium_Logo_G3.svg",
                                            file: "/sistem/Chartarium_Logo_G3.svg",
                                            x: 1000,
                                            y: 1200,
                                            width: 1000,
                                            height: 1000,
                                            stroke: '#000000',
                                            opacity: 1,
                                            strokeWidth: 0,
                                            draggable: true,
                                            keepRatio: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 20,
                                            shadowOffsetX: 0,
                                            shadowOffsetY: 0,
                                            shadowOpacity: 0,
                                        },
                                        {
                                            component: 'v-image',
                                            type: 'image',
                                            name: 'Logo Chartarium',
                                            url: "https://api.chartarium.ro/storage/templates/sistem/Chartarium_Logo_G4.svg",
                                            file: "/sistem/Chartarium_Logo_G4.svg",
                                            x: 1000,
                                            y: 1200,
                                            width: 1000,
                                            height: 1000,
                                            stroke: '#000000',
                                            opacity: 1,
                                            strokeWidth: 0,
                                            draggable: true,
                                            keepRatio: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 20,
                                            shadowOffsetX: 0,
                                            shadowOffsetY: 0,
                                            shadowOpacity: 0,
                                        },
                                        {
                                            component: 'v-image',
                                            type: 'image',
                                            name: 'Logo Chartarium',
                                            url: "https://api.chartarium.ro/storage/templates/sistem/Chartarium_Logo_G5.svg",
                                            file: "/sistem/Chartarium_Logo_G5.svg",
                                            x: 1000,
                                            y: 1200,
                                            width: 1000,
                                            height: 1000,
                                            stroke: '#000000',
                                            opacity: 1,
                                            strokeWidth: 0,
                                            draggable: true,
                                            keepRatio: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 20,
                                            shadowOffsetX: 0,
                                            shadowOffsetY: 0,
                                            shadowOpacity: 0,
                                        },
                                         {
                                            component: 'v-image',
                                            type: 'image',
                                            name: 'Logo Chartarium',
                                            url: "https://api.chartarium.ro/storage/templates/sistem/Chartarium_Logo_G6.svg",
                                            file: "/sistem/Chartarium_Logo_G6.svg",
                                            x: 1000,
                                            y: 1200,
                                            width: 1000,
                                            height: 1000,
                                            stroke: '#000000',
                                            opacity: 1,
                                            strokeWidth: 0,
                                            draggable: true,
                                            keepRatio: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 20,
                                            shadowOffsetX: 0,
                                            shadowOffsetY: 0,
                                            shadowOpacity: 0,
                                        },
                                        {
                                            component: 'v-image',
                                            type: 'image',
                                            name: 'Logo Chartarium',
                                            url: "https://api.chartarium.ro/storage/templates/sistem/Chartarium_Logo_G7.svg",
                                            file: "/sistem/Chartarium_Logo_G7.svg",
                                            x: 1000,
                                            y: 1200,
                                            width: 1000,
                                            height: 1000,
                                            stroke: '#000000',
                                            opacity: 1,
                                            strokeWidth: 0,
                                            draggable: true,
                                            keepRatio: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 20,
                                            shadowOffsetX: 0,
                                            shadowOffsetY: 0,
                                            shadowOpacity: 0,
                                        },
                                        {
                                            component: 'v-image',
                                            type: 'image',
                                            name: 'Logo Chartarium',
                                            url: "https://api.chartarium.ro/storage/templates/sistem/Chartarium_Logo_G8.svg",
                                            file: "/sistem/Chartarium_Logo_G8.svg",
                                            x: 1000,
                                            y: 1200,
                                            width: 1000,
                                            height: 1000,
                                            stroke: '#000000',
                                            opacity: 1,
                                            strokeWidth: 0,
                                            draggable: true,
                                            keepRatio: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 20,
                                            shadowOffsetX: 0,
                                            shadowOffsetY: 0,
                                            shadowOpacity: 0,
                                        },
                                    ]
                                },



                                // Shape items
                                {
                                    name: 'Forme',
                                    children: [],
                                    open: true,
                                    templates: [{
                                            //id: 6,
                                            name: 'Patrat',
                                            component: 'v-rect',
                                            type: 'rectangle',
                                            rotation: 0,
                                            x: 10,
                                            y: 810,
                                            file: "/sistem/patrat.svg",
                                            fill: '#FF43C7FF',
                                            stroke: '#000',
                                            cornerRadius: 0,
                                            strokeWidth: 0,
                                            width: 900,
                                            height: 900,
                                            draggable: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 10,
                                            shadowOffsetX: 40,
                                            shadowOffsetY: 40,
                                            shadowOpacity: 1,
                                        },
                                        {
                                            //id: 7,
                                            name: 'Cerc',
                                            component: 'v-circle',
                                            type: 'circle',
                                            x: 800,
                                            y: 800,
                                            file: "/sistem/cerc.svg",
                                            radius: 350,
                                            fill: '#1AA7A7FF',
                                            stroke: '#000',
                                            strokeWidth: 0,
                                            draggable: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 50,
                                            shadowOffsetX: 40,
                                            shadowOffsetY: 40,
                                            shadowOpacity: 1,
                                        },
                                        {
                                            id: 8,
                                            name: 'Poligon',
                                            file: "/sistem/poligon.svg",
                                            component: 'v-regular-polygon',
                                            type: 'polygon',
                                            x: 400,
                                            y: 900,
                                            sides: 6,
                                            radius: 350,
                                            fill: '#615BFF6A',
                                            stroke: '#000',
                                            strokeWidth: 30,
                                            draggable: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 10,
                                            shadowOffsetX: 40,
                                            shadowOffsetY: 40,
                                            shadowOpacity: 1,

                                        },
                                        {
                                            id: 9,
                                            name: 'Stea',
                                            component: 'v-star',
                                            type: 'star',
                                            x: 400,
                                            y: 500,
                                            file: "/sistem/stea.svg",
                                            numPoints: 6,
                                            innerRadius: 350,
                                            outerRadius: 200,
                                            fill: '#FFEC00FF',
                                            stroke: '#000',
                                            strokeWidth: 30,
                                            draggable: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 10,
                                            shadowOffsetX: 40,
                                            shadowOffsetY: 40,
                                            shadowOpacity: 1,

                                        },
                                        {
                                            id: 10,
                                            name: 'Linie',
                                            component: 'v-line',
                                            file: "/sistem/linie.svg",
                                            type: 'line',
                                            points: [0, 0, 500, 0],
                                            stroke: '#FF43C7FF',
                                            strokeWidth: 50,
                                            x: 1200,
                                            y: 500,
                                            draggable: true,
                                            shadowColor: '#000000',
                                            shadowBlur: 0,
                                            shadowOffsetX: 40,
                                            shadowOffsetY: 40,
                                            shadowOpacity: 0,
                                        }
                                    ]
                                }
                            ]
                        },

                    ]
                }

                let response = await this.$axios.get('/template-categories');
                editor.children.push(...response.data);

                console.log('>> editor.children', editor.children);

                let folder = { id: 'dosare', name: 'dosarele mele', children: [] }
                let plans = { id: 'plans', name: 'Planuri', children: []};
                let accounts = { id: 'accounts', name: 'Conturi', children: []};

                this.data.push(resource);
                this.data.push(editor);
                this.data.push(folder);
                this.data.push(plans);
                this.data.push(accounts);

                if (this.$route.name === 'Editor Resurse') {
                    this.expandedSection = this.data[1].name;
                    this.selectedSectionItem = this.data[1].children[0];
                }

            } catch (error) {
                console.log(error);
            }


        },

        //Animation functions
        beforeEnterV: function(el) {
            el.style.height = '0';
        },
        enterV: function(el) {
            el.style.height = el.scrollHeight + 16 + 'px';
        },
        beforeLeaveV: function(el) {
            el.style.height = el.scrollHeight + 16 + 'px';
        },
        leaveV: function(el) {
            el.style.height = '0';
        },
        beforeEnterH: function(el) {
            el.style.width = '0';
        },
        enterH: function(el) {
            el.style.width = '290px';
        },
        beforeLeaveH: function(el) {
            el.style.width = '290px';
        },
        leaveH: function(el) {
            el.style.width = '0';
        }
    }
}
</script>