<template>
    <div class="view post">
        <div class="title">
            <div class="pull-right">
                <router-link to="/main/blog" style="color: inherit !important;">
                    <v-icon>mdi-chevron-left</v-icon> Inapoi la lista
                </router-link>
            </div>
            Edit Post
        </div><br>
        <div class="post" v-if="post">
            <v-row>
                <v-col cols="9">
                    <div class="field-group">
                        <v-text-field v-model="post.title" outlined label="Titlu" dense hide-details></v-text-field><br>
                    </div>
                    <div class="field-group">
                        <v-text-field v-model="post.slug" outlined label="URL" dense hide-details></v-text-field><br>
                    </div>
                    <div class="field-group">
                        <editor v-model="post.body" api-key="2as2ydvdqbfb10eu39mooeb199eij8gu9ytong2jj71jx4og" :init="{
                         height: 500,
                         menubar: false,
                         plugins: [
                           'advlist autolink lists link image charmap print preview anchor',
                           'searchreplace visualblocks code fullscreen',
                           'insertdatetime media table paste code help wordcount'
                         ],
                         toolbar:
                           'undo redo | formatselect | bold italic backcolor | \
                           alignleft aligncenter alignright alignjustify | \
                           bullist numlist outdent indent | removeformat | help'
                       }" />
                    </div>
                </v-col>
                <v-col cols="3">
                    Imagine:<br>
                    <div @click="clickFile" class="image">
                        <input @change="uploadFile" ref="file" type="file" hidden>
                        <img v-if="!preview" v-auth-image="$axios.defaults.baseURL + post.image" width="100%" />
                        <img v-if="preview" :src="preview" alt="" width="100%">
                    </div>
                    <div class="actions">
                        <v-btn @click="update" class="chartarium-button-full w-100" rounded color="#2E4DD4">Save</v-btn><br><br>
                        <v-btn @click="showDeleteModal = true" class="chartarium-button-full w-100" rounded color="red">Delete Post</v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="modals">
            <v-dialog class="app-modal" v-model="showDeleteModal" persistent max-width="620">
                <v-card class="app-modal__card">
                    <div class="app-modal__card--close">
                        <inline-svg @click.stop="showDeleteModal = false" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4" />
                    </div>
                    <v-form ref="form">
                        <div class="app-modal__card--content">
                            <h1 class="app-modal__title">Sterge post</h1>
                            <div class="app-modal__card--folder-title">
                                Esti sigur ca vrei sa stergi acest post?<br>
                                <b>{{ post.title }}</b>
                            </div>

                        </div>
                    </v-form>
                    <div class="app-modal__card--actions">
                        <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="showDeleteModal = false">
                            <span>Inapoi</span>
                        </v-btn>
                        <v-btn class="app-modal__btn" elevation="4" rounded color="red" @click.stop="deletePost" style="margin-left: 15px; color: #fff !important;">
                            <span style="color: #fff !important;">Delete Post</span>
                        </v-btn>
                    </div>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "Post.scss";
</style>
<script>
import Editor from '@tinymce/tinymce-vue'
export default {
    name: "AccountsView",
    data() {
        return {
            showDeleteModal: false,
            post: null,
            file: null,
            preview: null,
        }
    },
    components: {
        Editor,
    },
    async mounted() {
        await this.getPost();
    },
    methods: {
        clickFile() {
            this.file = null;
            this.preview = null;
            this.$refs.file.click();

        },
        uploadFile(event) {
            this.file = event.target.files[0];
            this.preview = URL.createObjectURL(this.file);
        },
        async update() {
            try {
                let postData = new FormData();
                postData.append('title', this.post.title);
                postData.append('slug', this.post.slug);
                postData.append('body', this.post.body);
                if (this.file) {
                    postData.append('image', this.file);
                }
                let response = await this.$axios.post('posts/' + this.$route.params.id, postData);
                this.$toasted.success('Post updated');
            } catch (error) {
                this.$toasted.error('Server error');
                console.log(error);
            }
        },
        async deletePost() {
            try {
                let response = await this.$axios.delete('posts/' + this.$route.params.id);
                this.$router.push('/main/blog');
            } catch (error) {
                this.$toasted.error('Server error');
                console.log(error);
            }
        },
        async getPost() {
            try {
                let response = await this.$axios.get('posts/' + this.$route.params.id);
                this.post = response.data;
            } catch (error) {
                this.$toasted.error('Server error');
                console.log(error);
            }
        }
    }
}
</script>