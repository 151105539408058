var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view post"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"pull-right"},[_c('router-link',{staticStyle:{"color":"inherit !important"},attrs:{"to":"/main/blog"}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" Inapoi la lista ")],1)],1),_vm._v(" Create Post ")]),_c('br'),(_vm.post)?_c('div',{staticClass:"post"},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"field-group"},[_c('v-text-field',{attrs:{"outlined":"","label":"Titlu","dense":"","hide-details":""},model:{value:(_vm.post.title),callback:function ($$v) {_vm.$set(_vm.post, "title", $$v)},expression:"post.title"}}),_c('br')],1),_c('div',{staticClass:"field-group"},[_c('v-text-field',{attrs:{"outlined":"","label":"URL","dense":"","hide-details":""},model:{value:(_vm.post.slug),callback:function ($$v) {_vm.$set(_vm.post, "slug", $$v)},expression:"post.slug"}}),_c('br')],1),_c('div',{staticClass:"field-group"},[_c('editor',{attrs:{"api-key":"2as2ydvdqbfb10eu39mooeb199eij8gu9ytong2jj71jx4og","init":{
				         height: 500,
				         menubar: false,
				         plugins: [
				           'advlist autolink lists link image charmap print preview anchor',
				           'searchreplace visualblocks code fullscreen',
				           'insertdatetime media table paste code help wordcount'
				         ],
				         toolbar:
				           'undo redo | formatselect | bold italic backcolor | \
				           alignleft aligncenter alignright alignjustify | \
				           bullist numlist outdent indent | removeformat | help'
				       }},model:{value:(_vm.post.body),callback:function ($$v) {_vm.$set(_vm.post, "body", $$v)},expression:"post.body"}})],1)]),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Imagine:"),_c('br'),_c('div',{staticClass:"image",on:{"click":_vm.clickFile}},[_c('input',{ref:"file",attrs:{"type":"file","hidden":""},on:{"change":_vm.uploadFile}}),(_vm.preview)?_c('img',{attrs:{"src":_vm.preview,"alt":"","width":"100%"}}):_vm._e(),(!_vm.preview)?_c('div',{staticClass:"upload text-center",staticStyle:{"padding":"50px 0px"}},[_vm._v(" Click to upload ")]):_vm._e()]),_c('div',{staticClass:"actions"},[_c('v-btn',{staticClass:"chartarium-button-full w-100",attrs:{"rounded":"","color":"#2E4DD4"},on:{"click":_vm.update}},[_vm._v("Save")])],1)])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }