<template>
    <v-dialog class="app-modal" v-model="show" persistent max-width="620">
        <v-card class="app-modal__card">
            <div class="app-modal__card--close">
                <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4" />
            </div>
            <v-form ref="form">
                <div class="app-modal__card--content">
                    <h1 class="app-modal__title">Sterge Plan</h1>
                    <div class="app-modal__card--folder-title">
                        Esti sigur ca vrei sa stergi acest plan?<br>
                        <b>{{ data.name}}</b>
                    </div>
                </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="save">
                    <inline-svg :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#2e4dd4" />
                    <span>Sterge</span>
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props: ['title', 'btnIcon', 'btnIconSize', 'btnTitle', 'value', 'moveResource', 'editFolder', 'selectedItem', 'isActualMove', 'data'],
    components: {
        'inline-svg': InlineSvg
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        async save() {
            try {
                let response = await this.$axios.delete('plans/' + this.data.id);
                this.$toasted.success('Plan creat');
                this.close();
                this.$emit('updated');
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        close() {
            this.show = false;
        },
    }
}
</script>