<template>
    <div class="view plans">
        <div class="title">
            Conturi
        </div><br>
        <div class="plans" v-if="accounts">
            <v-simple-table class="w-100">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                ID
                            </th>
                            <th class="text-left">
                                Nume
                            </th>
                            <th class="text-left">
                                Email
                            </th>
                            <th class="text-left">
                                Organizatie
                            </th>
                            <th class="text-left">
                                Useri Activi
                            </th>
                            <th class="text-left">
                                Plan
                            </th>
                            <th class="text-right">
                                Actiuni
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in accounts" :key="item.id">
                            <td>#{{ item.id }}</td>
                            <td><b v-if="item.users.find(e=> { return (e.pivot.type == 'owner')})">{{ item.users.find(e=> { return (e.pivot.type == 'owner')}).name }}</b></td>
                            <td><span v-if="item.users.find(e=> { return (e.pivot.type == 'owner')})">{{ item.users.find(e=> { return (e.pivot.type == 'owner')}).email }}</span></td>
                            <td><b>{{ item.name }}</b></td>
                            <td>{{ item.users.length }}</td>
                            <td><span v-if="item.plan"> {{ item.plan.name }}</span></td>
                            <td class="text-right">
                                <v-chip @click="selected = item; modals.change = true;">Schimba Plan</v-chip>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div class="modals">
        	<!-- <change-plan @updated="getAccounts()" v-if="selected" :data="selected" v-model="modals.change" /> -->
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "Accounts.scss";
</style>
<script>
import ChangePlan from './modals/ChangePlan.vue';
export default {
    name: "AccountsView",
    data() {
        return {
            accounts: null,
            selected: null,
            modals: {
            	change: null,
            }
        }
    },
    components: {
    	ChangePlan,
    },
    async mounted() {
        await this.getAccounts();
    },
    methods: {
        async getAccounts() {
            let response = await this.$axios.get('accounts');
            this.accounts = response.data;
        }
    }
}
</script>