<template>
    <div class="control-panel" v-if="this.data" data-aos="fade">
        <div class="control-panel__breadcrumb">
            <v-breadcrumbs :items="breadcrumbs">
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :to="item.to" :disabled="false" ripple active-class="control-panel__breadcrumb-item">
                        {{ item.text.toUpperCase() }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
            <div class="control-panel__actions">
                <v-btn v-if="canEdit" @click.stop="()=>$emit('onEdit', data)" class="chartarium-button-hollow chartarium-button-hollow--primary" rounded elevation="0" color="#fff">
                    <span>edit</span>
                    <inline-svg src='/img/pencil.svg' width="18" height="18" />
                </v-btn>
                <v-btn v-if="canDelete" @click.stop="()=>$emit('onDelete', data)" class="chartarium-button-hollow chartarium-button-hollow--primary" rounded elevation="0" color="#fff" :disabled="false">
                    <span>delete</span>
                    <v-icon color="#2e4dd4">mdi-delete</v-icon>
                </v-btn>
            </div>
        </div>
        <div class="control-panel__title">
            <h1>{{this.data.name}}</h1>
        </div>
    </div>
</template>
<script>
import InlineSvg from 'vue-inline-svg';

export default {
    props: ['data', 'canEdit', 'canDelete'],
    components: {
        'inline-svg': InlineSvg,
    },
    mounted() {

        this.createBreadcrumb(this.data);


    },
    data() {
        return {
            breadcrumbs: [],
            resourcesCategories: null,
        }
    },
    methods: {
        getResources() {
            this.$axios.get('/resource-categories')
                .then(res => {
                    this.resourcesCategories = res.data;
                })
                .then(() => this.createBreadcrumb(this.data))
        },
        createBreadcrumb(data) {
            let breadcrumbs = [];
            var current =  {
                parent: (this.$route.name.includes('Folders')) ? data : data.categories,
            }
            while (current.parent) {
                current = current.parent;
                breadcrumbs.push({
                    text: current.name,
                    to: { path: "/main/folders/" + current.id }
                });
            }
            this.breadcrumbs = breadcrumbs.reverse();
            /*
            RIP PROGRAMMING 2023
            let obj = {
                    text: data.name,
                    disabled:false,
                    to:null,
                }
            if(this.$route.name.includes('Folders')){
                if(Object.prototype.hasOwnProperty.call(data, "parent")){
                    obj.to = {path:"/main/folders/"+data.id}
                    this.breadcrumbs.push(obj);

                    if(data.parent !== null) {
                        this.createBreadcrumb(data.parent)
                    } else if(data.parent === null) {
                        this.breadcrumbs.reverse();
                    }
                } else if(Object.prototype.hasOwnProperty.call(data, "folders")){
                    obj.to = {path: "/main/folders/"+data.folders[0].id+"/resource/"+data.id}
                    this.breadcrumbs.push(obj);
                    this.createBreadcrumb(data.folders[0]);
                }
            } else if (this.$route.name.includes('Resource')) {
                if(Object.prototype.hasOwnProperty.call(data, "categories") ){
                    obj.to = {path: "/main/resources/"+data.categories.id+"/resource/"+data.id}
                    this.breadcrumbs.push(obj);
                    console.log('added', obj);
                    this.createBreadcrumb(data.categories);
                }
                if(Object.prototype.hasOwnProperty.call(data, "parent")){
                    
                    if (this.recursiveSearch(this.resourcesCategories, data.id).children.length === 0){
                        obj.to = {path:"/main/resources/"+data.id}
                    } else {
                        obj.disabled = true;
                    }
                    
                    
                    this.breadcrumbs.push(obj);
                    
                    if(data.parent !== null) {
                        this.createBreadcrumb(data.parent)
                    } else if(data.parent === null) {
                        this.breadcrumbs.reverse();
                    }
                } 
            }
            */
        },
        recursiveSearch(data, matchingItem) {
            for (let t = 0; t < data.length; t++) {
                if (data[t].id === matchingItem) {
                    return data[t];
                } else if (data[t].children.length !== 0) {
                    let result = null;
                    for (let i = 0; result == null && i < data[t].children.length; i++) {
                        result = this.recursiveSearch(data[t].children, matchingItem);
                    }
                    if (result) return result;
                }
            }
            return null;
        }
    }
}
</script>