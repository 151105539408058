<template>
    <div class="view blog">
        <div class="title">
            <div class="pull-right">
                <router-link :to="'/main/blog/new'">
                    <v-btn  class="chartarium-button-full" rounded color="#2E4DD4">
                        Adauga Post
                        <v-icon right dark>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </router-link>
            </div>
            Blog
        </div><br>
        <div class="posts" v-if="posts">
            <v-simple-table class="w-100">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left" style="width: 50px;">
                                ID
                            </th>
                            <th class="text-left" style="width: 150px;">
                                Imagine
                            </th>
                            <th class="text-left" style="width: 150px">
                                Titlu
                            </th>
                            <th class="text-left" style="width: 150px">
                                Text
                            </th>
                            <th class="text-left">
                                URL
                            </th>
                            <th class="text-right"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in posts" :key="item.id">
                            <td>#{{ item.id }}</td>
                            <td>
                                <img v-auth-image="$axios.defaults.baseURL+item.image" width="100%" />
                            </td>
                            <td>
                                {{ item.title }}
                            </td>
                            <td>
                                {{ item.slug }}
                            </td>
                            <td>
                                {{ item.body.substring(0, 400) }}..
                            </td>
                            <td class="text-right">
                                <router-link :to="'/main/blog/' + item.id">
                                    <v-chip class="table-action">Edit</v-chip>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "Blog.scss";
</style>
<script>
export default {
    name: "AccountsView",
    data() {
        return {
            posts: null,
        }
    },
    components: {},
    async mounted() {
        await this.getPosts();
    },
    methods: {
        async getPosts() {
            try {
                let response = await this.$axios.get('posts');
                this.posts = response.data;
            } catch (error) {
                this.$toasted.error('Server error');
                console.log(error);
            }
        }
    }
}
</script>