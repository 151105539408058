<template>
    <div class="view post">
        <div class="title">
            <div class="pull-right">
                <router-link to="/main/blog" style="color: inherit !important;">
                    <v-icon>mdi-chevron-left</v-icon> Inapoi la lista
                </router-link>
            </div>
            Create Post
        </div><br>
        <div class="post" v-if="post">
            <v-row>
                <v-col cols="9">
                    <div class="field-group">
                        <v-text-field v-model="post.title" outlined label="Titlu" dense hide-details></v-text-field><br>
                    </div>
                    <div class="field-group">
                        <v-text-field v-model="post.slug" outlined label="URL" dense hide-details></v-text-field><br>
                    </div>
                    <div class="field-group">
                        <editor v-model="post.body" api-key="2as2ydvdqbfb10eu39mooeb199eij8gu9ytong2jj71jx4og" :init="{
				         height: 500,
				         menubar: false,
				         plugins: [
				           'advlist autolink lists link image charmap print preview anchor',
				           'searchreplace visualblocks code fullscreen',
				           'insertdatetime media table paste code help wordcount'
				         ],
				         toolbar:
				           'undo redo | formatselect | bold italic backcolor | \
				           alignleft aligncenter alignright alignjustify | \
				           bullist numlist outdent indent | removeformat | help'
				       }" />
                    </div>
                </v-col>
                <v-col cols="3">
                	Imagine:<br>
                    <div @click="clickFile" class="image">
                        <input @change="uploadFile" ref="file" type="file" hidden>
                        <img v-if="preview" :src="preview" alt="" width="100%">
                        <div class="upload text-center" v-if="!preview" style="padding: 50px 0px;">
                        	Click to upload
                        </div>
                    </div>
                    <div class="actions">
                        <v-btn @click="update" class="chartarium-button-full w-100" rounded color="#2E4DD4">Save</v-btn>

                        
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "Post.scss";
</style>
<script>
import Editor from '@tinymce/tinymce-vue'
export default {
    name: "AccountsView",
    data() {
        return {
            post: {
            	title: null,
            	slug: null,
            	body: null,
            },
            file: null,
            preview: null,
        }
    },
    components: {
        Editor,
    },
    async mounted() {
        
    },
    methods: {
        clickFile() {
            this.file = null;
            this.preview = null;
            this.$refs.file.click();

        },
        uploadFile(event) {
            this.file = event.target.files[0]; 
            this.preview = URL.createObjectURL(this.file);
        },
        async update() {
            try {
                let postData = new FormData();
                postData.append('title', this.post.title);
                postData.append('slug', this.post.slug);
                postData.append('body', this.post.body);
                if(this.file) {
                    postData.append('image', this.file);
                }
                let response = await this.$axios.post('posts', postData);
                this.$toasted.success('Post created');
                this.$router.push('/main/blog');
            } catch (error) {
                this.$toasted.error('Server error');
                console.log(error);
            }
        },
    }
}
</script>