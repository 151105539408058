<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="620"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form">
            <div class="app-modal__card--content">
                <h1 class="app-modal__title">{{title}}</h1>
                <br>
                <p class="app-modal__text" v-if="!isResource">Sunteti sigur/a ca doriti sa stergeti acest dosar?<br> Aceasta operatie este ireversibila iar stergerea dosarului implica si stergerea tuturor resurselor din dosar.</p>
                <p class="app-modal__text" v-if="isResource">Sunteti sigur/a ca doriti sa stergeti aceasta resursa?<br> Aceasta operatie este ireversibila.</p>
                
            </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn app-modal__btn--delete" elevation="4" rounded color="#fff" @click.stop="deleteFolder">
                    <v-icon v-if='mdi'>{{this.btnIcon}}</v-icon>
                    <inline-svg v-else :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#F21B3F"/>
                    <span>{{btnTitle}}</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props:['title','btnIcon', 'btnIconSize','btnTitle','value','moveResource','editFolder','mdi','selectedItem',"isResource"],
    components:{
        'inline-svg':InlineSvg
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
        }
    },
    mounted(){
    },
    methods: {
        close(){
           this.show = false;
        },
        deleteFolder(){
        
            this.loading = true;
            if(this.isResource){
                this.$axios.delete('/resources/'+this.selectedItem.id)
                    .then(()=>{
                        this.show = false;
                        this.$toasted.success('Resursa a fost stersa');
                        this.$router.push('/main/folders/'+ this.$route.params.folders_id);
                    })
                    .catch(err=>{
                        this.$toasted.error(err)
                        this.show = false;
                    });  
            }
            else {
                this.$axios.delete('/folders/'+this.selectedItem.id)
                    .then(res=>{
                        this.$root.$emit('refetchMenu',res);
                        this.show = false;
                        this.$toasted.success('Dosarul a fost sters');
                        if(this.selectedItem.parent_id!==null){
                            this.$router.push('/main/'+this.$account+'/folders/'+ this.selectedItem.parent_id);
                        } else {
                            this.$router.push('/main/'+this.$account+'/');
                        }
                        
                    })
                    .catch(err=>{
                        this.$toasted.error(err)
                        this.show = false;
                    });  
            }
                      
        },
        
    }

}
</script>