<template>
    <v-dialog
        class="app-modal"
        v-model="visible"
        persistent
        max-width="620"
    >
      <v-card class="app-modal__card  edit-icon-dialog">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form">
            <div class="app-modal__card--content">
                <h1 class="app-modal__title">Edit fisier</h1>
                <div class="app-modal__card--folder-title">
                    <div class="icon-holder">
                        <img class="icon" v-auth-image="'https://api.chartarium.ro/storage/templates' + icon.file" alt="">
                    </div>
                    <div class="form">
                        <v-text-field 
                            v-model="temp.name" 
                            outlined 
                            label="Nume"
                            :rules="[() => !!temp.name || 'Campul este obligatoriu']"
                            ref="title"
                            :disabled="confirmDelete"
                        ></v-text-field>
                        <v-text-field 
                            v-model="temp.file" 
                            outlined 
                            label="Fisier"
                            disabled
                        ></v-text-field>
                        <v-select
                        v-model="temp.demo" 
                        outlined 
                        label="Demo"
                        ref="demo"
                        :items="[{name: 'Nu', id: 0 },{name: 'Da', id: 1}]"
                        item-text="name"
                        item-value="id"
                        dense
                        hide-details
                    ></v-select>
                    <br>
                    </div>
                </div>
                <div class="confirm-delete" v-if="confirmDelete">
                    Esti sigur ca vrei sa stergi acest fisier?
                </div>
            </div>
            </v-form>
            <div class="app-modal__card--actions" v-if="confirmDelete == false">
                <v-btn class="app-modal__btn delete" elevation="4" rounded color="#fff"   @click.stop="remove">
                    <inline-svg :src="'/img/save.svg'"  fill="#2E4765"/>
                    <span style="color: #2E4765;">Sterge</span>
                </v-btn>
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="update">
                    <inline-svg :src="'/img/save.svg'"  fill="#2e4dd4"/>
                    <span>Salveaza</span>
                </v-btn>
            </div>
            <div class="app-modal__card--actions" v-else>
                <v-btn class="app-modal__btn delete" elevation="4" rounded color="#fff"   @click.stop="remove">
                    <inline-svg :src="'/img/save.svg'"  fill="#f83540"/>
                    <span style="color: #f83540;">Da, sterge</span>
                </v-btn>
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="confirmDelete = false">
                    <inline-svg :src="'/img/times.svg'"  fill="#2e4dd4"/>
                    <span style="color: #2E4765">Nu, cancel</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<style lang="scss">
@import 'IconModal.scss';
</style>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props:['icon'],
    components:{
        'inline-svg': InlineSvg
    },
    data() {
        return {
            visible: true,
            temp: null,
            confirmDelete: false,
        }
    },
    mounted(){
        console.log(this.icon);
        this.temp = JSON.parse(JSON.stringify(this.icon));
    },
    methods: {
        close(){
            this.$emit('close');
        },
        async update() {
            try {
                let response = await this.$axios.put('/templates/' + this.icon.id, this.temp);
                this.$toasted.success("Fisier urcat");
                this.$emit('update');
                this.$emit('close');
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async remove() {
            if(this.confirmDelete == false) {
                this.confirmDelete = true;
                return;
            }

             try {
                let response = await this.$axios.delete('/templates/' + this.icon.id);
                this.$toasted.success("Fisier urcat");
                this.$emit('update');
                this.$emit('close');
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }

        },
    }

}
</script>