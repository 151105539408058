<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="620"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form">
            <div class="app-modal__card--content">
                <h1 class="app-modal__title">Creaza folder</h1>
                <div class="app-modal__card--folder-title">
                    <v-text-field 
                        v-model="temp.name" 
                        outlined 
                        label="Nume"
                        :rules="[() => !!temp.name || 'Campul este obligatoriu']"
                        ref="title"
                    ></v-text-field>
                </div>
            </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="postFolder">
                    <inline-svg :src="'/img/save.svg'"  fill="#2e4dd4"/>
                    <span>Salveaza Folder</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props:['value', 'parent'],
    components:{
        'inline-svg':InlineSvg
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            temp:{
                name:null,
                parent_id: this.parent.id,
            },
            loading: false,
        }
    },
    mounted(){
        
    },
    methods: {
        close(){
            this.show = false;
            this.temp.name = null;
            this.$refs.form.resetValidation();
        },
        validate(){
            let valid = true;
            if(this.temp.name === null || this.temp.parent_id === null || this.temp.name === undefined || this.temp.parent_id === undefined) {
                valid = false;
            }
            return valid;
        },
        async postFolder(){
            this.loading = true;
            if(!this.validate()) {
               this.$toasted.error('Toate campurile trebuiesc completate'); 
               this.loading = false;
               return; 
            }
            try {
                let response = await this.$axios.post('/template-categories', this.temp);
                this.$emit('update');
                this.close();
            } catch (error) {
                this.$toasted.error("Eroare la server");
            }

            
        },
    }

}
</script>