<template>
    <div class="nav">
        <div class="nav__logo">
            <v-img src="/img/logo.png" width="61" height="47" />
            <div class="details" style="display: flex; flex-direction: column; padding-top: 10px; padding-left: 25px;">
                <div class="location" style="font-size: 14px; font-weight: 600;" v-if="newResource">
                    Nesalvat
                </div>
                <div class="location" style="font-size: 14px; font-weight: 600;" v-if="resource">
                    <v-breadcrumbs :items="breadcrumbs" style="padding: 0px !important; pointer-events: none;">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :disabled="false" ripple active-class="control-panel__breadcrumb-item">
                                {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </div>
                <div class="category" style="font-size: 24px; font-weight: 600; color: #2E4765; margin-top: 0px;"><span v-if="resource">{{ resource.name }}</span><span v-if="newResource">RESURSA NOUĂ</span></div>
            </div>
        </div>
        <div class="nav__center">
            <!-- center -->
            <div class="undo" style="padding-top: 20px;">
                <!-- <v-icon size="40px" style="cursor: pointer;">mdi-undo</v-icon>
                <v-icon size="40px" style="margin-left: 20px; cursor: pointer;" disabled>mdi-redo</v-icon> -->
            </div>
        </div>
        <div class="nav__user">
            <v-select label="Orientation" v-model="orientation" :items="['portrait', 'landscape']" style="position: relative; top: 12px; width: 100px;" dense width="50px">
            </v-select>
            <v-btn @click.stop="clickExport" dark rounded color="secondary">
                EXPORT
                <v-icon right dark>
                    mdi-download
                </v-icon>
            </v-btn>
            <v-btn @click.stop="clickSave" dark rounded color="#2E4DD4" v-if="resource">
                SALVEAZĂ
                <v-icon right dark>
                    mdi-content-save
                </v-icon>
            </v-btn>
            <v-btn @click.stop="clickSaveAs" dark rounded color="#2E4DD4">
                SALVEAZĂ COPIE
                <v-icon right dark>
                    mdi-content-save
                </v-icon>
            </v-btn>
            <v-divider class="vertical-divider" vertical inset />
            <v-avatar color="#2E4DD4" size="36">
                <img v-if="this.getUser.image != null" style="object-fit:cover" v-auth-image="$axios.defaults.baseURL + this.getUser.image" />
            </v-avatar>
            <v-menu offset-y offset-x nudge-bottom="28" nudge-right="10" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                        mdi-chevron-down
                    </v-icon>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="cursor-pointer" @click="clickLogoutAccount">Schimbare Cont</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title class="cursor-pointer" @click="clickLogout">Delogare</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            orientation: 'portrait',
            newResource: false,
            resource: null,
            breadcrumbs: [],
        }
    },
    computed: mapGetters(["getUser"]),
    mounted() {

        if(this.$route.query.orientation) {
            this.orientation = this.$route.query.orientation;
        }

        this.$root.$on('setEditorOrientation', (orientation) => {
            if(this.orientation != orientation) {
                this.orientation = orientation;
            }
        });

        this.$root.$on('setResource', (resource) => {
            this.resource = resource;
            this.createBreadcrumb(this.resource);
        });

        this.$root.$on('setNewResource', () => {
            this.newResource = true;
        });
    },
    watch: {
        'orientation': {
            handler() {
                this.$root.$emit('setHeaderOrientation', this.orientation);
            }
        }
    },
    methods: {
        ...mapActions(['logout', 'logoutAccount']),
        clickSaveAs() {
            this.$root.$emit('clickSaveAs');
        },
        clickSave() {
            this.$root.$emit('clickSave');
        },
        clickExport() {
            this.$root.$emit('clickExport');
        },
        clickLogout() {
            this.logout()
                .then(() => {
                    this.$router.push('/login');
                });
        },
        clickLogoutAccount() {
            this.logoutAccount()
                .then(() => {
                    this.$router.push('/account');
                })
        },
        createBreadcrumb(data) {
            let obj = {
                text: data.name,
                disabled: false,
                to: null,
            }

            if (Object.prototype.hasOwnProperty.call(data, "parent")) {
                obj.to = { path: "/main/folders/" + data.id }
                this.breadcrumbs.push(obj);

                if (data.parent !== null) {
                    this.createBreadcrumb(data.parent)
                } else if (data.parent === null) {
                    this.breadcrumbs.reverse();
                    this.breadcrumbs.pop();
                }
            } else if (Object.prototype.hasOwnProperty.call(data, "folders")) {
                obj.to = { path: "/main/folders/" + data.folders[0].id + "/resource/" + data.id }
                this.breadcrumbs.push(obj);
                this.createBreadcrumb(data.folders[0]);
            }

        }
    }
}
</script>