<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="620"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form">
            <div class="app-modal__card--content">
                <h1 class="app-modal__title">Adauga Plan</h1>
                <div class="app-modal__card--folder-title">
                    <v-text-field 
                        v-model="temp.name" 
                        outlined 
                        label="Nume"
                        ref="title"
                        dense
                        hide-details
                    ></v-text-field><br>
                    <v-select
                        v-model="temp.type" 
                        outlined 
                        label="Tip"
                        :items="['personal', 'business']"
                        dense
                        hide-details
                    ></v-select><br>
                    <v-text-field 
                        v-model="temp.max_users" 
                        outlined 
                        label="Max Useri"
                        type="number"
                        dense
                        hide-details
                    ></v-text-field><br>
                    <v-text-field 
                        v-model="temp.max_folders" 
                        outlined 
                        label="Max Foldere"
                        type="number"
                        dense
                        hide-details
                    ></v-text-field><br>
                     <v-text-field 
                        v-model="temp.print_level" 
                        outlined 
                        label="Print Level"
                        type="number"
                        dense
                        hide-details
                    ></v-text-field><br>
                    <v-checkbox v-model="temp.resource_access" label="Acces Resurse" dense></v-checkbox>
                    <v-checkbox v-model="temp.resource_edit" label="Edit Resurse" dense></v-checkbox>
                    <v-checkbox v-model="temp.edit_logo" label="Edit Logo" dense></v-checkbox>
                    <v-checkbox v-model="temp.edit_text" label="Edit Text" dense></v-checkbox>
                </div>
            </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="save">
                    <inline-svg :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#2e4dd4"/>
                    <span>Salveaza</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props:['title','btnIcon', 'btnIconSize','btnTitle','value','moveResource','editFolder','selectedItem', 'isActualMove'],
    components:{
        'inline-svg':InlineSvg
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            temp:{
                name:null,
                type: 'personal',
                resource_access: false,
                resource_edit: false,
                edit_text: false,
                edit_logo: false,
            },
            loading: false,
        }
    },
    mounted() {
        
    },
    watch: {
       
    },
    methods: {
    	async save() {
    		try {
    			let response = await this.$axios.post('plans', this.temp);
    			this.$toasted.success('Plan creat');
    			this.close();
    			this.$emit('updated');
    		} catch (error) {
    			console.log(error);
    			this.$toasted.error('Server error');
    		}
    	},
        close(){
            this.show = false;
         	this.temp = {
         		name: null,
         		type: 'personal',
         		resource_access: false,
                resource_edit: false,
                edit_text: false,
                edit_logo: false,
         	}
        },
    }
}
</script>