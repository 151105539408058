<template>
    <v-dialog class="app-modal" v-model="show" persistent max-width="620">
        <v-card class="app-modal__card">
            <div class="app-modal__card--close">
                <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4" />
            </div>
            <v-form ref="form">
                <div class="app-modal__card--content">
                    <h1 class="app-modal__title">{{folder.name}}</h1>
                    <br>
                    <p class="app-modal__text">Sunteti sigur/a ca doriti sa stergeti acest dosar?<br><br> Aceasta operatie este ireversibila iar stergerea dosarului implica si stergerea tuturor resurselor din dosar.</p>
                </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn app-modal__btn--delete" elevation="4" rounded color="#fff" @click.stop="deleteFolder">
                    <v-icon>mdi-delete</v-icon>
                    <span>Confirma</span>
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props: ['value', 'folder'],
    components: {
        'inline-svg': InlineSvg
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {
        close() {
            this.show = false;
        },
        async deleteFolder() {

            this.loading = true;
            try {   
                let response = await this.$axios.delete('/template-categories/' + this.folder.id);
                this.$emit('update');
                this.close();
            } catch (error) {
                this.$toasted.error('Server error');
            }
            

        },

    }

}
</script>