<template>
    <div class="class-options text">
        <div class="sections" v-if="item">
            <!-- Background -->
            <div class="section">
                <v-menu v-model="showTextEditor" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <div class="label" @click="toggleTextEditor"  style="min-width: 112px;">
                            {{item.text.substring(0, 15) }}<span v-if="item.text.length > 15">...</span>
                        </div>
                    </template>
                    <v-textarea id="textEditor" rows="3" style="width: 300px; " hide-details solo v-model="item.text"></v-textarea>                    
                </v-menu>
                <!-- <v-text-field @click="openTextEditor" height="0px" style="width: 100px; color: #000 !important;"  hide-details dense flat class="field-font-size no-line" v-model.number="item.text"  /> -->
            </div>
            <div class="section">
                <div class="label strong">Font</div>
                <!-- Color -->
                <div class="label">Color:</div>
                <color-picker :item="item" fieldname="fill" />
                <!-- Size -->
                <div class="label">Size:</div>
                <v-text-field height="0px" hide-details type="number" step="10" min="80" max="900" dense flat class="field-font-size no-line" v-model.number="item.fontSize" />
                <!-- Font Family -->
                <div class="label">Face:</div>
                <v-select style="max-width: 90px;"" :items=" fonts" v-model="item.fontFamily" height="0px" hide-details dense flat class="field-font-size no-line" />
                <v-icon class="format-icon" :class="{'active': bold }" @click="bold = !bold">mdi-format-bold</v-icon>
                <v-icon class="format-icon" :class="{'active': italic }" @click="italic = !italic">mdi-format-italic</v-icon>
                <v-icon class="format-icon" :class="{'active': underline }" @click="underline = !underline">mdi-format-underline</v-icon>
                <v-icon class="format-icon" :class="{'active': strikethrough }" @click="strikethrough = !strikethrough">mdi-format-strikethrough</v-icon>
            </div>
            <!-- Border -->
            <div class="section">
                <div class="label strong">Border</div>
                <!-- Color -->
                <div class="label">Color:</div>
                <color-picker :item="item" fieldname="stroke" />
                <!-- Size -->
                <div class="label">Size:</div>
                <v-text-field height="0px" hide-details type="number" step="10" min="0" max="99" dense flat class="field-font-size no-line" v-model.number="item.strokeWidth" />
            </div>
            <!-- Shadow -->
            <div class="section">
                <div class="label strong">Shadow</div>
                <!-- Color -->
                <div class="label">Color:</div>
                <color-picker :item="item" fieldname="shadowColor" />
                <!-- Blur -->
                <div class="label">Blur:</div>
                <v-text-field height="0px" hide-details type="number" step="10" min="0" max="99" dense flat class="field-font-size no-line" v-model.number="item.shadowBlur" />
                <!-- Offset X -->
                <div class="label">X:</div>
                <v-text-field height="0px" hide-details type="number" step="10" min="0" max="99" dense flat class="field-font-size no-line" v-model.number="item.shadowOffsetX" />
                <!-- Offset Y -->
                <div class="label">Y:</div>
                <v-text-field height="0px" hide-details type="number" step="10" min="0" max="99" dense flat class="field-font-size no-line" v-model.number="item.shadowOffsetY" />
                <!-- Offset Y -->
                <div class="label">Opacity:</div>
                <v-text-field height="0px" hide-details type="number" step="0.1" min="0" max="1" dense flat class="field-font-size no-line" v-model.number="item.shadowOpacity" />
            </div>
        </div>
    </div>
</template>
<script>
import ColorPicker from './components/ColorPicker.vue';
import $ from 'jquery';
export default {
    name: 'RectangleShapeOptions',
    props: ['selected'],
    components: {
        ColorPicker,
    },
    data() {
        return {
            showTextEditor: false,
            item: null,
            fonts: [
                'Calibri',
                'Arial',
                'Verdana',
                'System',
                'Roboto',
                'Encode Sans',
                'Playpen Sans',
                'Dancing Script',
                'Kalam',
                'Patrick Hand',
                'Comic Neue',
                'Mali',
                'Lemonada',
                'Grandstander',
                'Patrick Hand SC',

            ]
        }
    },
    computed: {
        bold: {
            get() {
                let styleOptions = this.item.fontStyle.split(' ');
                return styleOptions.includes('bold');
            },
            set(newValue) {
                let styleOptions = this.item.fontStyle.split(' ');
                if (newValue === true) {
                    if (!styleOptions.includes('bold')) {
                        styleOptions.push('bold');
                    }
                } else {
                    if (styleOptions.includes('bold')) {
                        styleOptions.splice(styleOptions.indexOf('bold'), 1);
                    }
                }

                this.item.fontStyle = styleOptions.join(' ');
            },
        },
        italic: {
            get() {
                let styleOptions = this.item.fontStyle.split(' ');
                return styleOptions.includes('italic');
            },
            set(newValue) {
                let styleOptions = this.item.fontStyle.split(' ');
                if (newValue === true) {
                    if (!styleOptions.includes('italic')) {
                        styleOptions.push('italic');
                    }
                } else {
                    if (styleOptions.includes('italic')) {
                        styleOptions.splice(styleOptions.indexOf('italic'), 1);
                    }
                }

                this.item.fontStyle = styleOptions.join(' ');
            },
        },

        underline: {
            get() {
                return (this.item.textDecoration === 'underline');
            },
            set(newValue) {
                if (newValue === true) {
                    this.item.textDecoration = 'underline';
                } else {
                    this.item.textDecoration = '';
                }

            },
        },
        strikethrough: {
            get() {
                return (this.item.textDecoration === 'line-through');
            },
            set(newValue) {
                if (newValue === true) {
                    this.item.textDecoration = 'line-through';
                } else {
                    this.item.textDecoration = '';
                }
            },
        },


    },
    watch: {
        'selected': {
            handler() {
                this.item = null;
                this.$nextTick(() => {
                    this.item = this.selected;
                });

            }
        },
        'item.strokeWidth': {
            handler(newStrokeWidth) {

            }
        }
    },
    mounted() {
        this.item = this.selected;
    },
    methods: {
        toggleTextEditor() {
            this.showTextEditor = true;
            setTimeout(function() {
                $("#textEditor").click();
            }, 50);               
        }
    }
}
</script>