<template>
    <div class="view file-manager">
        <div class="item" v-if="data">
            <div class="head">
                <div class="actions">
                    <v-btn @click="modals.deleteFolder = true" rounded elevation="0" class="btn-empty btn-red" v-if="data.parent_id">
                        <span>sterge</span> <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn @click="modals.editFolder = true" rounded elevation="0" class="btn-empty" v-if="data.parent_id">
                        <span>edit</span> <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="modals.createFolder = true" rounded elevation="0" class="btn-full" >
                        <span>folder nou</span> <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </div>
                <div class="breadcrumb">
                    <router-link class="no-link item" :to="'/main/file-manager/' + item.id" v-for="item in breadcrumb">
                            {{ item.name }}
                    </router-link>
                </div>
            </div>
            <v-row class="folders">
                <v-col cols="2" v-for="folder in data.children">
                    <router-link :to="'/main/file-manager/' + folder.id" class="no-link">
                        <div class="folder">
                            <div class="name">{{ folder.name }}</div>
                            <div class="count">{{ folder.children.length }} subfoldere</div>
                            <div class="count">{{ folder.templates.length }} icoane</div>
                            <div class="previews">
                                <div class="preview"></div>
                                <div class="preview"></div>
                                <div class="preview"></div>
                                <div class="preview"></div>
                            </div>
                        </div>
                    </router-link>
                </v-col>
            </v-row>
            <v-row class="templates">
            	<v-col cols="1"  v-if="data.parent_id">
                    <input ref="fileUpload" type="file" hidden multiple class="custom-file-input" id="fileUpload" @change="handleFileUpload">
            		<div class="template add" @click="$refs.fileUpload.click()">
            			<img src="@/assets/img/icon_add.svg" alt="" class="icon">
            			<div class="name">
                            FISIER
                        </div>
            		</div>
            	</v-col>
                <v-col cols="1" v-for="template in data.templates">
                    <div class="template" @click="selectIcon(template)">
                        <img class="icon" v-auth-image="'https://api.chartarium.ro/storage/templates' + template.file" alt="">
                        <div class="demofeat" style="position: absolute; top: 2px; left: 5px;">
                            <div style="background-color: #ef3f2b; color: #fff; display: inline-block; margin-right: 10px; border-radius: 6px; padding: 2px 5px; font-weight: bold; font-size: 10px;" v-if="template.demo">DEMO</div>
                        </div>
                        <div class="name">
                            {{ template.name }}
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
        <div class="modals">
            <create-folder-modal @update="getData" v-model="modals.createFolder" v-if="data" :parent="data" />
            <edit-folder-modal @update="getData" v-model="modals.editFolder" v-if="data" :folder="data" />
            <delete-folder-modal @update="redirectToParent" v-model="modals.deleteFolder" v-if="data" :folder="data" />
            <icon-modal v-if="selectedIcon" :icon="selectedIcon" @close="selectedIcon = null" @update="getData" />
        </div>
    </div>
</template>
<style lang="scss">
@import 'FileManager.scss';
</style>
<script>
import CreateFolderModal from './components/CreateFolderModal/CreateFolderModal.vue';
import DeleteFolderModal from './components/DeleteFolderModal/DeleteFolderModal.vue';
import EditFolderModal from './components/EditFolderModal/EditFolderModal.vue';
import IconModal from './components/IconModal/IconModal.vue';
export default {
    data() {
        return {
            selectedIcon: null,
            loading: false,
            data: null,
            modals: {
                createFolder: false,
                deleteFolder: false,
                editFolder: false,
            }
        }
    },
    components: {
        CreateFolderModal,
        DeleteFolderModal,
        EditFolderModal,
        IconModal,
    },
    computed: {
        breadcrumb() {
            let pageArray = [];
            let current = this.data;
            while (current) {
                pageArray.push({
                    name: current.name,
                    id: current.id,
                });
                current = (current.parent) ? current.parent : null;
            }
            return pageArray.reverse();
        }
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        selectIcon(icon) {
            this.selectedIcon = icon;
        },
        async handleFileUpload(event) {
            try {
                this.loading = true;
                let files = event.target.files;
                let postData = new FormData();
                postData.append('template_category_id', this.data.id);
                for (var i = 0; i < event.target.files.length; i++) {
                    let file = event.target.files[i];
                    postData.append(`files[${i}]`, file);
                }
                let response = await this.$axios.post('/templates', postData);
                console.log('upload success', response.data);
                this.loading = false;
                this.$toasted.success('Fisiere urcate');
                this.$refs.fileUpload.value = '';
                await this.getData();
            } catch (error) {
                this.$toasted.error('Eroare la upload');
                console.log('upload error', error.response.data);
                this.loading = false;
                this.$refs.fileUpload.value = '';
            }
        },
        redirectToParent() {
            this.$router.push('/main/file-manager/' + this.data.parent_id);
        },
        async getData() {
            try {
                this.data = null;
                let response = await this.$axios.get('template-categories/' + this.$route.params.id);
                this.data = response.data;
            } catch (error) {
                this.$toasted.error('Server error');
                console.log(error);
            }
        }
    }
}
</script>