<template>
    <div class="view editor">
        <div class="bar options">
            <div class="type">
                {{ selected.type }}
            </div>
            <div class="type-options">
                <rectangle-options :selected="selected" v-if="selected && selected.type == 'rectangle'" />
                <circle-options :selected="selected" v-if="selected && selected.type == 'circle'" />
                <star-options :selected="selected" v-if="selected && selected.type == 'star'" />
                <polygon-options :selected="selected" v-if="selected && selected.type == 'polygon'" />
                <text-options :selected="selected" v-if="selected && selected.type == 'text'" />
                <image-options :selected="selected" v-if="selected && selected.type == 'image'" />
                <line-options :selected="selected" v-if="selected && selected.type == 'line'" />
            </div>
            <div class="general-options" v-if="selected">
                <!-- Lock Element -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="selected.draggable = !selected.draggable" :class="{'on': !selected.draggable}">{{ (selected.draggable) ? 'mdi-lock-open' : 'mdi-lock'}}</v-icon>
                    </template>
                    <span>{{(selected.draggable) ? '&nbsp;&nbsp;&nbsp;Blocare&nbsp;&nbsp;' : 'Deblocare'}}</span>
                </v-tooltip>
                <!-- Duplicate Element -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="duplicateItem(selected)">mdi-content-copy</v-icon>
                    </template>
                    <span>Duplicare</span>
                </v-tooltip>
                <!-- Set Element Z Order -->
                <v-menu offset-y left :close-on-content-click="false">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                                    mdi-layers-outline
                                </v-icon>
                            </template>
                            <span>Ordine</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item @click="moveItemZ(selected, 'top')" link>
                            <v-list-item-title>
                                <v-icon>mdi-chevron-double-up</v-icon> Fața
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="moveItemZ(selected, 'front')" link>
                            <v-list-item-title>
                                <v-icon>mdi-chevron-up</v-icon> Sus
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="moveItemZ(selected, 'back')" link>
                            <v-list-item-title>
                                <v-icon>mdi-chevron-down</v-icon> Jos
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="moveItemZ(selected, 'bottom')" link>
                            <v-list-item-title>
                                <v-icon>mdi-chevron-double-down</v-icon> Spate
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <!-- Delete Element -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="deleteItem(selected)">mdi-delete</v-icon>
                    </template>
                    <span>Şterge</span>
                </v-tooltip>
            </div>
        </div>
        <div class="scene" ref="scene">
            <v-stage class="stage" ref="stage" :config="stageConfig" @mousedown="handleStageMouseDown" @touchstart="handleStageMouseDown" @dragend="handleDragEnd">
                <v-layer ref="layer">
                    <component v-for="item in scene" :is="item.component" :key="item.id" :config="item" @transformend="handleTransformEnd" />
                    <v-transformer v-if="$refs.stage" ref="transformer" :config="{flipEnabled: false}" />
                </v-layer>
            </v-stage>
        </div> 

        <resource-update-details v-if="resource" :resource="resource" :orientation="orientation" v-model="showUpdateModal" :btnIcon="'save'" :btnIconSize="24" :title="'Creeaza Dosar'" :btnTitle="'Salveaza'" :moveResource="true" :postData="{ scene: JSON.stringify(scene), 
                         render: render}" />

        <resource-save-to-folder :orientation="orientation" v-model="showSaveModal" :btnIcon="'save'" :btnIconSize="24" :title="'Creeaza Dosar'" :btnTitle="'Salveaza'" :moveResource="true" :postData="{ scene: JSON.stringify(scene), 
                         render: render}" />
    </div>
</template>
<script>
import Konva from 'vue-konva';
import $ from 'jquery';
import RectangleOptions from './options/RectangleOptions';
import CircleOptions from './options/CircleOptions';
import StarOptions from './options/StarOptions';
import PolygonOptions from './options/PolygonOptions';
import TextOptions from './options/TextOptions';
import ImageOptions from './options/ImageOptions';
import LineOptions from './options/LineOptions';
import DefaultScene from './DefaultScene.js';
import ResourceSaveToFolder from '@/components/molecules/EditorSaveToFolder.vue';
import ResourceUpdateDetails from '@/components/molecules/EditorUpdateDetails.vue';
export default {
    components: {
        RectangleOptions,
        CircleOptions,
        StarOptions,
        TextOptions,
        ImageOptions,
        LineOptions,
        PolygonOptions,
        ResourceSaveToFolder,
        ResourceUpdateDetails,
    },
    data() {
        return {
            orientation: 'portrait',
            resource: null,
            showSaveModal: false,
            showUpdateModal: false,
            stageConfig: {
                width: 100,
                height: 100,
                scaleX: 1,
                scaleY: 1,
            },
            scene: [{
                type: 'background',
                component: 'v-rect',
                listening: false,
                name: 'Background',
                rotation: 0,
                x: 0,
                y: 0,
                fill: '#fff',
                width: 99999,
                height: 999999,
            }, ],
            render: null,
            selected: '',
        }
    },
    async created() {



    },

    async mounted() {





        if (this.$route.params.id !== 'new') {
            let response = await this.$axios.get('resources/' + this.$route.params.id);
            this.resource = response.data;
            this.loadScene(this.resource.scene);
            this.$root.$emit('setResource', this.resource);
        } else {
            this.$nextTick(() => {
                this.$root.$emit('setNewResource');
            });

        }

        this.$root.$on('setHeaderOrientation', (orientation) => {
            console.log('set header orientation ', orientation);
            if(this.orientation != orientation) {
                this.orientation = orientation;
            }

            this.fitPageToWindow();
        });

        if (this.resource && this.resource.orientation) {
            this.orientation = this.resource.orientation;
            this.$root.$emit('setEditorOrientation', this.orientation);
        }

        this.fitPageToWindow();
        this.$root.$on('openEditorLevelTwo', (open) => {
            //console.log('menu', open)
        });
        this.$root.$on('clickExport', () => {
            function downloadURI(uri, name) {
                let link = document.createElement('a');
                link.download = name;
                link.href = uri;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                link = null;
            }
            this.selected = '';
            this.updateTransformer();
            let stageNode = this.$refs.stage.getNode();
            this.render = stageNode.toDataURL({ pixelRatio: 20 });
            downloadURI(this.render, 'export.jpg');
        });

        this.$root.$on('clickSaveAs', () => {
            this.selected = '';
            this.updateTransformer();
            let stageNode = this.$refs.stage.getNode();
            this.render = stageNode.toDataURL({ pixelRatio: 1 });
            this.showSaveModal = true;
        });


        this.$root.$on('clickSave', () => {
            this.selected = '';
            this.updateTransformer();
            let stageNode = this.$refs.stage.getNode();
            this.render = stageNode.toDataURL({ pixelRatio: 1 });
            this.showUpdateModal = true;
        });

        this.$root.$on('selectEditor', this.insertItem);

       
        document.addEventListener("keydown", this.handleKeyPress, false);


        //this.$root.$on('clickSave', this.saveResource);

    },
    beforeDestroy() {
        this.$root.$off('selectEditor');
        this.$root.$off('openEditorLevelTwo');
        this.$root.$off('clickSaveAs');
        this.$root.$off('clickExport');

        document.removeEventListener("keydown", this.handleKeyPress, false);
    },
    watch: {
        // Draggable state change
        'selected.draggable': {
            handler() {

                if (this.selected == '') {
                    return;
                }

                let transformerNode = this.$refs.transformer.getNode();
                // Enable / disable transformer based on draggable prop
                if (this.selected.draggable) {
                    transformerNode.setAttr('opacity', 1);
                    transformerNode.setAttr('listening', true);
                } else {
                    transformerNode.setAttr('opacity', 0.5);
                    transformerNode.setAttr('listening', false);
                }

            },
        },
        'selected': {

            handler() {

                if (this.selected == '') {
                    return;
                }



                let transformerNode = this.$refs.transformer.getNode();
                let stageNode = this.$refs.stage.getNode();
                let selectedNode = stageNode.findOne('#' + this.selected.id);



                // Custom anchors for different objecct types
                if (this.selected.type !== 'text') {
                    transformerNode.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']);
                } else {
                    transformerNode.enabledAnchors(['middle-left', 'middle-right']);
                }

                // Custom transform function for text and shapes
                selectedNode.on('transform', () => {

                    // Text tranform
                    if (this.selected.type == 'text') {
                        selectedNode.setAttrs({
                            width: selectedNode.width() * selectedNode.scaleX(),
                            scaleX: 1,
                        });
                        this.selected.width = selectedNode.width() * selectedNode.scaleX();
                        this.selected.scaleX = 1;
                    }

                    // Rectangle transformer
                    if (this.selected.type == 'rectangle') {
                        this.selected.width = selectedNode.width() * selectedNode.scaleX();
                        this.selected.height = selectedNode.height() * selectedNode.scaleY();
                        this.selected.scaleX = 1;
                        this.selected.scaleY = 1;
                    }

                });

                // Set transformer boundbox based on selected type
                transformerNode.boundBoxFunc(function(oldBox, newBox) {
                    // width and height of the boxes are corresponding to total absolute width and height of all nodes combined
                    // so it includes scale of the node.
                    if (newBox.width < 20) {
                        return oldBox;
                    }
                    return newBox;
                });

            },

        }
    },
    methods: {
        handleKeyPress(event) {
            if(event.code == 'Delete') {
                if(this.selected) {
                    this.deleteItem(this.selected);
                }
            }
        },
        generateId() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        },
        /* loadImage(path) {
             return new Promise((resolve, reject) => {
                 const img = new Image()
                 img.crossOrigin = 'Anonymous' // to avoid CORS if used with Canvas
                 img.src = path
                 img.onload = () => {
                     resolve(img)
                 }
                 img.onerror = e => {
                     reject(e)
                 }
             })
         },*/
        async loadScene(scene) {
            scene = JSON.parse(scene);
            for (let key = 0; key <= scene.length; key++) {
                let item = scene[key];
                if (item.type == 'image') {
                    let tempImage = new window.Image();
                    tempImage.src = item.url;
                    tempImage.crossOrigin = 'Anonymous';
                    await tempImage.decode();
                    this.scene.push({
                        ...item,
                        image: tempImage,
                    });
                } else {
                    this.scene.push(item);
                }
            }
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        },
        async saveResource() {

            this.selected = '';
            this.updateTransformer();
            let stageNode = this.$refs.stage.getNode();
            this.render = stageNode.toDataURL({ pixelRatio: 3 });


            try {

                let postData = new FormData();
                postData.append('scene', JSON.stringify(this.scene));
                let tempFile = this.dataURLtoFile(this.render, 'render.png');
                postData.append('render', tempFile);
                postData.append('orientation', this.orientation);

                let response = await this.$axios.post('resources/' + this.resource.id, postData);

                this.$toasted.success('Resource updated');

            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async insertItem(item) {

            console.log('adding item > ', item);

            let stageNode = this.$refs.stage.getNode();
            if (item.type == 'image') {

                let tempImage = new window.Image();
                tempImage.src = item.url;
                tempImage.crossOrigin = 'Anonymous';

                var self = this;
                tempImage.onload = function() {

                    console.log(this.width);
                    console.log(this.height);

                    self.scene.push({
                        id: self.generateId(),
                        image: tempImage,
                        ...item,
                        width: this.width * 2,
                        height: this.height * 2,
                        x: ((stageNode.attrs.width / stageNode.attrs.scaleX) / 2),
                        y: ((stageNode.attrs.height / stageNode.attrs.scaleY) / 4),
                    });

                };





            } else {
                this.scene.push({
                    ...item,
                    id: this.generateId(),
                    x: ((stageNode.attrs.width / stageNode.attrs.scaleX) / 2),
                    y: ((stageNode.attrs.height / stageNode.attrs.scaleY) / 4),
                });
            }
        },
        duplicateItem(item) {

            let itemIndex = this.scene.findIndex(e => {
                return e.id === item.id;
            });

            let newItem = Object.assign({}, item);
            newItem.id = this.generateId();
            newItem.x = newItem.x + 100;
            newItem.y = newItem.y + 100;
            newItem.draggable = true;

            // Insert after cloned item 
            this.scene.splice(itemIndex + 1, 0, newItem);


            this.$nextTick(() => {
                this.selected = newItem;
                this.updateTransformer();
            });

        },
        deleteItem(selected) {

            let deletedItemIndex = this.scene.findIndex(e => {
                return e.id === selected.id;
            });
            if (deletedItemIndex === null) {
                return;
            }

            this.scene.splice(deletedItemIndex, 1);
            this.selected = '';
            this.updateTransformer();

        },
        moveItemZ(selected, direction) {

            let movedItemIndex = this.scene.findIndex(e => {
                return e.id === selected.id;
            });
            if (movedItemIndex === null) {
                return;
            }

            switch (direction) {

                case 'front':
                    if (movedItemIndex < this.scene.length - 1) {
                        let temp = this.scene[movedItemIndex];
                        this.scene[movedItemIndex] = this.scene[movedItemIndex + 1];
                        this.scene[movedItemIndex + 1] = temp;
                    }
                    break;

                case 'back':
                    if (movedItemIndex > 0) {
                        let temp = this.scene[movedItemIndex];
                        this.scene[movedItemIndex] = this.scene[movedItemIndex - 1];
                        this.scene[movedItemIndex - 1] = temp;
                    }
                    break;

                case 'top':
                    if (movedItemIndex !== this.scene.length - 1) {
                        let temp = this.scene[movedItemIndex];
                        this.scene.splice(movedItemIndex, 1);
                        this.scene.push(temp);
                    }
                    break;

                case 'bottom':
                    if (movedItemIndex !== 0) {
                        let temp = this.scene[movedItemIndex];
                        this.scene.splice(movedItemIndex, 1);
                        this.scene.splice(0, 0, temp);
                    }
                    break;


            }

            // Refresh canvas 
            this.$forceUpdate();

        },

        fitPageToWindow() {

            console.log('orientation', this.orientation);

            let orientation = this.orientation;
            let minPadding = 150;

            let windowWidth = window.innerWidth - 185;
            let windowHeight = window.innerHeight - 120;

            if (orientation === 'portrait') {
                // A4 Paper size
                var A4Width = 2480;
                var A4Height = 3508;
            } else {
                var A4Width = 3508;
                var A4Height = 2480;
            }

            // Max height
            let pageHeight = windowHeight - minPadding;
            let pageWidth = pageHeight * A4Width / A4Height;


            this.stageConfig.width = pageWidth;
            this.stageConfig.height = pageHeight;

            let scale = pageWidth / A4Width;



            this.stageConfig.scaleX = scale;
            this.stageConfig.scaleY = scale;

            $('.stage').css('top', minPadding / 2);


        },
        handleTransformEnd(e) {

            // shape is transformed, let us save new attrs back to the node
            // find element in our state
            const rect = this.scene.find(
                (r) => r.id === this.selected.id
            );
            // update the state
            rect.x = e.target.x();
            rect.y = e.target.y();
            rect.rotation = e.target.rotation();
            rect.scaleX = e.target.scaleX();
            rect.scaleY = e.target.scaleY();

            // change fill
        },
        handleStageMouseDown(e) {

            // filter native events
            if (!e.evt) {
                return;
            }
            // clicked on stage - clear selection
            if (e.target === e.target.getStage()) {
                this.selected = '';
                this.updateTransformer();
                return;
            }

            // clicked on transformer - do nothing                
            if (e.target.getParent().className === 'Transformer') {
                return;
            }

            // find clicked item by id
            const clickedId = e.target.attrs.id;
            const item = this.scene.find((r) => r.id === clickedId);
            if (item) {
                this.selected = item;
            } else {
                this.selected = '';
            }
            this.updateTransformer();
        },
        handleDragEnd(e) {

            // filter native events
            if (!e.evt) {
                return;
            }
            // clicked on stage - clear selection
            if (e.target === e.target.getStage()) {
                return;
            }

            // clicked on transformer - do nothing
            if (e.target.className === 'Transformer') {
                return;
            }

            // find dragged item by id
            const draggedId = e.target.attrs.id;
            const item = this.scene.find((r) => r.id === draggedId);
            if (item) {
                item.x = e.target.attrs.x;
                item.y = e.target.attrs.y;
            }

        },
        updateTransformer() {

            if (!this.$refs.transformer) {
                return;
            }

            // here we need to manually attach or detach Transformer node
            const transformerNode = this.$refs.transformer.getNode();
            const stage = transformerNode.getStage();
            const { selected } = this;
            const selectedNode = stage.findOne('#' + selected.id);

            // do nothing if selected node is already attached
            if (selectedNode === transformerNode.node()) {
                return;
            }

            if (selectedNode) {
                // attach to another node
                transformerNode.nodes([selectedNode]);
            } else {
                // remove transformer
                transformerNode.nodes([]);
            }
        },
    },
}
</script>