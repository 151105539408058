<template>
    <v-dialog class="app-modal" v-model="show" persistent max-width="620">
        <v-card class="app-modal__card">
            <div class="app-modal__card--close">
                <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4" />
            </div>
            <v-form ref="form">
                <div class="app-modal__card--content">
                    <h1 class="app-modal__title">Schimba Plan</h1>

                    <div class="app-modal__card--folder-title">
                        <v-select v-model="plan_id" outlined label="Pas" ref="level" :items="plans" item-text="name" item-value="id" dense hide-details></v-select>
                    </div>
                </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="save">
                    <inline-svg :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#2e4dd4" />
                    <span>Salveaza</span>
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props: ['title', 'btnIcon', 'btnIconSize', 'btnTitle', 'value', 'moveResource', 'editFolder', 'selectedItem', 'isActualMove', 'data'],
    components: {
        'inline-svg': InlineSvg
    },
    computed: {
        show: {
            get() {
                return this.value;               
            },
            set(value) {
                this.plan_id = this.data.plan_id;
                this.$emit('input', value);
            }
        }
    },
    data() {
        return {
            loading: false,
            plans: null,
            plan_id: null,
        }
    },
    async mounted() {
        await this.getPlans();
    },
    methods: {
        async getPlans() {
            try {
                let response = await this.$axios.get('plans');
                this.plans = response.data;
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async save() {
            try {

                let response = await this.$axios.post('accounts/' + this.data.id + '/change-plan', {
                    plan_id: this.plan_id,
                });
                this.$toasted.success('Cont updatat');
                this.close();
                this.$emit('updated');
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        close() {
            this.show = false;
        },
    }
}
</script>